import { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { useData } from './ContentProvider';
import useLedger from './useLedger';
import usePayments from './usePayments';
import { useUser } from './useUser';

import {
  selectUserPrimaryEmail,
  selectUserFirstName,
  selectUserLastName,
  selectEventSelected,
  selectEventTopUpFolioId,
  fetchUpcomingEventsAction,
  fetchProductsAction,
  sendEmailReceiptAction,
  createTopUpOrder,
  useAppDispatch,
  useAppSelector,
  AppDispatch,
  selectOrdersStatus,
} from 'store';

export const useOrders = () => {
  const { fetchUserData } = useUser();
  const isOrdersLoading = useAppSelector(selectOrdersStatus) === 'loading';
  const userFirstName = useAppSelector(selectUserFirstName);
  const userLastName = useAppSelector(selectUserLastName);
  const userPrimaryEmail = useAppSelector(selectUserPrimaryEmail);
  const selectedEventId = useSelector(selectEventSelected, shallowEqual);
  const selectedEventTopUpFolioId = useSelector(selectEventTopUpFolioId, shallowEqual);
  const { currenWalletId, currentWalletRFID } = useLedger();
  const dispatch: AppDispatch = useAppDispatch();
  const { securedApi } = useData();
  const {
    processWithSavedPaymentMethod,
    pollPaymentStatus,
    processWithNewPaymentMethod,
    tokenizeCreditCard,
  } = usePayments();

  const fetchUpcomingEvents = useCallback(async () => {
    await dispatch(fetchUpcomingEventsAction({ securedApi }));
  }, [securedApi, dispatch]);

  const sendEmailReceipt = useCallback(
    async (orderId: string) => {
      await dispatch(sendEmailReceiptAction({ securedApi, orderId }));
    },
    [securedApi, dispatch],
  );

  const fetchTopUpTokenForFolio = useCallback(async () => {
    const topUpFolioId = selectedEventTopUpFolioId;
    const response = await dispatch(fetchProductsAction({ securedApi, topUpFolioId }));

    //Dirty hack to get the topUpToken
    const tokens = response.payload.entries.filter((entry: any) => {
      if (entry?.productSummary?.kind === 'token') {
        return entry;
      }
    });
    if (!tokens.length) {
      throw new Error('No Top-Up token found');
    }
    return tokens[0].uuid;
  }, [securedApi, dispatch, selectedEventTopUpFolioId]);

  const createTopUpOrderAndPayWithExistingCreditCard = async (
    paymentMethodId: string,
    amount: number,
  ) => {
    try {
      if (!userFirstName || !userLastName || !userPrimaryEmail) {
        await fetchUserData();
      }

      const topUpToken = await fetchTopUpTokenForFolio();

      const res = await dispatch(
        createTopUpOrder({
          selectedEventId,
          securedApi,
          userFirstName,
          userLastName,
          userPrimaryEmail,
          currenWalletId,
          selectedEventTopUpFolioId,
          topUpToken,
          amount,
          rfidUid: currentWalletRFID,
        }),
      ).unwrap();

      const paymentLink = res?.payload?.paymentInfo?.paymentLink;
      if (!paymentLink) {
        throw new Error('No payment link found');
      }

      const paymentId = paymentLink.split('paymentId=')[1];

      await Promise.all([
        processWithSavedPaymentMethod(paymentId, paymentMethodId),
        pollPaymentStatus(paymentId),
      ]);

      return res;
    } catch (error) {
      console.error('Error occurred in createTopUpOrderAndPayWithExistingCreditCard:', error);
      // @ts-ignore
      throw new Error(error);
    }
  };

  const createTopUpOrderAndPayWithNewCreditCard = async (cardInfo: any, amount: number) => {
    try {
      if (!userFirstName || !userLastName || !userPrimaryEmail) {
        await fetchUserData();
      }

      const topUpToken = await fetchTopUpTokenForFolio();

      const res = await dispatch(
        createTopUpOrder({
          selectedEventId,
          securedApi,
          userFirstName,
          userLastName,
          userPrimaryEmail,
          currenWalletId,
          selectedEventTopUpFolioId,
          topUpToken,
          amount,
          rfidUid: currentWalletRFID,
        }),
      ).unwrap();

      const paymentLink = res?.payload?.paymentInfo?.paymentLink;
      if (!paymentLink) {
        throw new Error('No payment link found');
      }

      const paymentId = paymentLink.split('paymentId=')[1];

      const spreedlyResult = await tokenizeCreditCard(cardInfo);

      const spreedlyToken = spreedlyResult?.payload?.transaction?.payment_method?.token;
      if (!spreedlyToken) {
        throw new Error('No Spreedly token found');
      }

      await Promise.all([
        processWithNewPaymentMethod(paymentId, spreedlyToken),
        pollPaymentStatus(paymentId),
      ]);

      return { paymentId, status: 'success' };
    } catch (error) {
      console.error('Error in createTopUpOrderAndPayWithNewCreditCard:', error);
      // @ts-ignore
      throw new Error(error);
    }
  };

  return {
    isOrdersLoading,
    fetchUpcomingEvents,
    createTopUpOrderAndPayWithExistingCreditCard,
    createTopUpOrderAndPayWithNewCreditCard,
    sendEmailReceipt,
  };
};
