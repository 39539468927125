import React from 'react';

import { WalletInfo as WalletInfoType } from 'data/api/types/LedgerApiTypes';
import { Section } from 'components/Section';

interface WalletInfoProps {
  currentWalletRFID: string;
  currentWallet: WalletInfoType;
  setOpenFreezeWristbandModal: (isOpen: boolean) => void;
  isWalletFrozen: boolean;
}

const WalletInfo = ({
  currentWalletRFID,
  currentWallet,
  setOpenFreezeWristbandModal,
  isWalletFrozen,
}: WalletInfoProps) => (
  <div className='bg-gray-100 w-full rounded-lg p-3 my-2 flex flex-col border-solid border-1'>
    <div className='flex flex-row justify-between text-base font-normal gap-4'>
      <Section label='UID' value={currentWalletRFID || 'N/A'} wrapperClassName='p-0' />
      <Section
        label='Security Code'
        value={currentWallet?.securityCode || 'N/A'}
        wrapperClassName='p-0'
      />
    </div>
    <div
      className='text-[#079286] underline cursor-pointer pt-2'
      onClick={() => setOpenFreezeWristbandModal(true)}
    >
      {isWalletFrozen ? 'Unfreeze Wallet' : 'Freeze Wallet'}
    </div>
  </div>
);
export default WalletInfo;
