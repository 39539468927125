import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { selectEventSelectedNameEn } from 'store';

const Breadcrumbs = () => {
  const location = useLocation();
  const eventName = useSelector(selectEventSelectedNameEn);

  const EventNameBreadcrumb = () => {
    if (!eventName) {
      return <Spinner size='sm' />;
    }
    return <span>{eventName}</span>;
  };

  const routes = [
    { path: '/events/:eventId', breadcrumb: EventNameBreadcrumb },
    { path: '/events', breadcrumb: 'My Event' },
    { path: '/', breadcrumb: null },
    { path: '/manage-profile-info', breadcrumb: null },
  ];
  const breadcrumbs = useBreadcrumbs(routes);

  if (location.pathname === '/events') return null;

  return (
    <nav>
      <div className='breadcrumbs flex p-4'>
        {breadcrumbs.map(({ breadcrumb, match }, index) => {
          const isEventBreadcrumb = match.pathname === `/events/${match.params.eventId}`;

          return (
            <div className='flex gap-2 items-center' key={match.pathname}>
              {isEventBreadcrumb ? (
                <>
                  <div className='text-intelli-inactive'>{breadcrumb}</div>
                  {index < breadcrumbs.length - 1 && (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className='text-intelli-inactive text-xs pr-2'
                    />
                  )}
                </>
              ) : (
                <Link
                  to={match.pathname || ''}
                  className={`${
                    match.pathname === location.pathname
                      ? 'text-custom-primary hover:text-custom-secondary'
                      : 'text-intelli-inactive hover:text-custom-secondary'
                  } flex items-center gap-2`}
                >
                  <div>{breadcrumb}</div>
                  {index < breadcrumbs.length - 1 && (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className='text-intelli-inactive text-xs pr-2'
                    />
                  )}
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </nav>
  );
};

export default Breadcrumbs;
