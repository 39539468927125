import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { menuClassNames } from 'components/Menu/Menu';

type LinkItem = {
  name: string;
  to?: string; // used with NavLink
  href?: string; // used with anchor tags
  icon?: React.ReactNode;
  target?: '_blank' | '_self' | '_parent' | '_top';
  isHidden?: boolean;
  onClick?: () => void;
};

type FooterMenuProps = {
  links: LinkItem[];
  variant?: 'primary' | 'secondary';
};

const FooterMenu: React.FC<FooterMenuProps> = ({ links, variant = 'secondary' }) => {
  return (
    <>
      <ul>
        {links
          .filter(({ isHidden }) => !isHidden)
          .map(({ onClick, to, name, icon, target }) => {
            return (
              <li key={name} className={`${menuClassNames[variant].li}  py-1`} onClick={onClick}>
                {target ? (
                  <a
                    className={classNames(
                      'flex md:pl-1.5 cursor-pointer items-center p-2 text-base font-semibold rounded-lg  hover:text-inherit',
                      menuClassNames[variant]['default'],
                      menuClassNames[variant]['hover'],
                    )}
                    href={to}
                    rel='noreferrer'
                    target={target}
                  >
                    <span className='w-4.5 h-4.5'>{icon}</span>
                    <span className='flex-1 text-base align-center ml-3'>{name}</span>
                  </a>
                ) : (
                  <>
                    <hr className='m-0 border-t border-x-intelli-tertiary border-opacity-20 px-1' />
                    <NavLink
                      className={classNames(
                        'flex cursor-pointer items-center py-2 text-base font-semibold rounded-lg hover:text-inherit pl-3 pr-2',
                        menuClassNames[variant]['default'],
                        menuClassNames[variant]['hover'],
                      )}
                      to={to || '#'} // Fallback for NavLink if `to` is undefined
                      end
                      onClick={onClick}
                    >
                      <span className='text-base align-center flex-1'>{name}</span>
                      <span className='w-4.5 h-4.5'>{icon}</span>
                    </NavLink>
                  </>
                )}
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default FooterMenu;
