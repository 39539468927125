import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';

export enum OrdersActions {
  FETCH_PRODUCTS = 'orders/fetchProducts',
  CREATE_TOP_UP_ORDER = 'orders/createTopUpOrder',
  SEND_EMAIL_RECEIPT = 'orders/sendEmailReceipt',
}

export const fetchProductsAction = createAsyncThunk(
  OrdersActions.FETCH_PRODUCTS,
  async ({ securedApi, topUpFolioId }: any) => {
    const response = await securedApi.OrdersApi.getProducts(topUpFolioId);
    return response;
  },
);

export const sendEmailReceiptAction = createAsyncThunk(
  OrdersActions.SEND_EMAIL_RECEIPT,
  async ({ securedApi, orderId }: any) => {
    try {
      const response = await securedApi.OrdersApi.sendEmailReceipt(orderId);
      toast.success(t('Transactions.toast.sendEmailReceiptSuccess'));
      return response;
    } catch (error: any) {
      toast.error(error.response.data.msg);
      return error;
    }
  },
);

export const createTopUpOrder = createAsyncThunk(
  OrdersActions.CREATE_TOP_UP_ORDER,
  async (props: any) => {
    const body = {
      contact_info: {
        email: props.userPrimaryEmail,
        first_name: props.userFirstName,
        last_name: props.userLastName,
        phone: '',
      },
      autoCheckout: true,
      buyerTimeZone: 'America/Toronto',
      orderingOnBehalfOf: {
        buyerInfo: {
          type: 'rfid',
          rfidUid: props.rfidUid,
        },
      },
      preferedLanguage: 'en',
      products: [
        {
          answers: [
            {
              questionUuid: 'walletId',
              response: props.currenWalletId, // this is wallet id from the user
            },
            {
              questionUuid: 'rfidUid',
              response: props.rfidUid,
            },
          ],
          quantity: props.amount,
          uuid: props.topUpToken,
        },
      ],
    };
    props.eventId = props.selectedEventTopUpFolioId; // this is the top up folio id

    const response = await props.securedApi?.OrdersApi.createTopUpOrder(props.eventId, body);
    return response;
  },
);
