import { useState, useEffect } from 'react';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';

import { auth } from '../firebase';

interface PasswordResetState {
  email: string | null;
  loading: boolean;
  error: string | null;
  success: boolean;
  isValidCode: boolean;
}

export const usePasswordReset = (oobCode: string | null) => {
  const [state, setState] = useState<PasswordResetState>({
    email: null,
    loading: false,
    error: null,
    success: false,
    isValidCode: false,
  });

  useEffect(() => {
    const verifyCode = async () => {
      if (!oobCode) {
        setState((prev) => ({
          ...prev,
          error: 'Invalid password reset link. Please request a new one.',
          isValidCode: false,
        }));
        return;
      }

      try {
        setState((prev) => ({ ...prev, loading: true, error: null }));
        const email = await verifyPasswordResetCode(auth, oobCode);
        setState((prev) => ({
          ...prev,
          email,
          loading: false,
          isValidCode: true,
        }));
      } catch (err) {
        setState((prev) => ({
          ...prev,
          loading: false,
          error: 'This password reset link is invalid or has expired. Please request a new one.',
          isValidCode: false,
        }));
      }
    };

    verifyCode();
  }, [oobCode]);

  const resetPassword = async (newPassword: string) => {
    if (!oobCode) {
      setState((prev) => ({
        ...prev,
        error: 'No reset code found. Please request a new password reset link.',
      }));
      return false;
    }

    try {
      setState((prev) => ({ ...prev, loading: true, error: null }));
      await confirmPasswordReset(auth, oobCode, newPassword);
      setState((prev) => ({ ...prev, loading: false, success: true }));
      return true;
    } catch (err) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error: err instanceof Error ? err.message : 'An unknown error occurred. Please try again.',
      }));
      return false;
    }
  };

  return {
    ...state,
    resetPassword,
  };
};
