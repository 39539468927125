import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { datadogRum } from '@datadog/browser-rum';

import { Config } from '../data/Config';

const extractDomain = (url: string) => {
  try {
    return new URL(url).origin;
  } catch (error) {
    console.error('Invalid URL in Config:', url);
    return null;
  }
};

const ContentSecurityPolicyController: React.FC = () => {
  const allowedDomains = new Set<string>();
  Object.values(Config).forEach((url) => {
    // Our BE microservices are auto-added to allowed domains
    if (typeof url === 'string') {
      const domain = extractDomain(url);
      if (domain) allowedDomains.add(domain);
    }
  });

  allowedDomains.add('https://unleash.intellitix.com'); // Unleash Feature Flags
  allowedDomains.add('https://www.gstatic.com'); // Google reCAPTCHA
  allowedDomains.add('https://intellitixrfid.zendesk.com'); // Zendesk API
  allowedDomains.add('https://intellitixguest.zendesk.com'); // Zendesk API
  allowedDomains.add('https://static.zdassets.com'); // Zendesk images
  allowedDomains.add('https://ekr.zdassets.com'); // Zendesk
  allowedDomains.add('https://identitytoolkit.googleapis.com'); // Google Auth API
  allowedDomains.add('https://securetoken.googleapis.com'); // Google Token API
  allowedDomains.add('https://api.smooch.io'); // Smooch.io used by Zendesk
  allowedDomains.add('wss://api.smooch.io'); // Smooch.io used by Zendesk
  allowedDomains.add('https://browser-intake-us5-datadoghq.com'); // DataDog Analytics

  const allowedScriptSources = [
    'https://core.spreedly.com',
    'https://unpkg.com',
    'https://static.zdassets.com',
    'https://www.google.com',
    'https://www.gstatic.com',
    'https://api.smooch.io',
  ];

  const cspRules = `
    default-src 'self'; 
    script-src 'self' ${allowedScriptSources.join(' ')};
    frame-src 'self' https://www.google.com/recaptcha/; 
    connect-src 'self' ${Array.from(allowedDomains).join(' ')}; 
    style-src 'self' 'unsafe-inline' https://fonts.googleapis.com; 
    font-src 'self' https://fonts.gstatic.com; 
    img-src 'self' data: https://intellitixrfid.zendesk.com; 
    img-src 'self' data: https://intellitixguest.zendesk.com; 
    object-src 'none'; 
    base-uri 'self'; 
    form-action 'self';
  `;

  useEffect(() => {
    // Script Monitoring: Detects unauthorized scripts in real-time
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeName === 'SCRIPT') {
              const script = node as HTMLScriptElement;
              if (script.src && !allowedScriptSources.some((src) => script.src.startsWith(src))) {
                const warningMessage = `🚨 Unauthorized script detected: ${script.src}`;

                console.warn(warningMessage);

                // Send alert to Datadog
                datadogRum.addAction('Unauthorized Script Detected', {
                  message: warningMessage,
                  script_src: script.src,
                  timestamp: new Date().toISOString(),
                });
              }
            }
          });
        }
      });
    });

    observer.observe(document.head, { childList: true, subtree: true });

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Helmet>
      <meta httpEquiv='Content-Security-Policy' content={cspRules.trim()} />
    </Helmet>
  );
};

export default ContentSecurityPolicyController;
