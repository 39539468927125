import React, { FC, useCallback, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, FormGroup } from 'reactstrap';
import { User } from 'firebase/auth';

import { Input, Button } from '../components';
import { useLoginForm } from '../hooks';
import { MobileLogin } from '../MobileLogin';

import { useAuthValue, useProvideAuth } from 'hooks/Firebase';
import { Navigation } from 'navigation';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

const Login: FC = () => {
  const { loginWithEmailPassword, isLoading } = useProvideAuth();
  const { control, onSubmit } = useLoginForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { refreshUser, currentUser } = useAuthValue();
  const isMobile = useMediaQuery();

  // Check if user is already logged in
  const checkLoggedIn = useCallback(() => {
    let userLoggedIn = false;

    refreshUser().then((user: User) => {
      if (user && user.emailVerified) {
        userLoggedIn = true;
        navigate(Navigation.EVENTS);
      }
    });
    return userLoggedIn;
  }, [navigate, refreshUser]);

  // Only call on first render
  useEffect(() => {
    checkLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMobile ? (
    <MobileLogin
      checkLoggedIn={checkLoggedIn}
      loginWithEmailPassword={loginWithEmailPassword}
      isLoading={isLoading}
    />
  ) : (
    <>
      {currentUser && currentUser?.emailVerified ? (
        <Navigate to={Navigation.EVENTS} replace />
      ) : (
        <>
          <Row className='text-left justify-content-between mb-3'>
            <Col xs='auto'>
              <h5>{t('Login.title')}</h5>
            </Col>
            <Col xs='auto'>
              <p className='fs--1 font-light text-[#748194]'>
                <Link to={Navigation.SIGN_UP}>{t('Login.link.createAccount')}</Link>
              </p>
            </Col>
          </Row>
          <Form onSubmit={onSubmit(loginWithEmailPassword, checkLoggedIn)}>
            <FormGroup>
              <Input
                name='loginEmail'
                type='email'
                className='form-control'
                placeholder={t('Login.placeholder.emailAddress')}
                control={control}
              />
            </FormGroup>
            <FormGroup>
              <Input
                name='loginPassword'
                type='password'
                className='form-control'
                placeholder={t('Login.placeholder.password')}
                control={control}
              />
            </FormGroup>
            <Row className='justify-content-between align-items-center'>
              <Col xs='auto'>
                {/* <Input
              className='auth-checkbox'
              id='isRemember'
              name='isRemember'
              label={t('Login.label.rememberMe')}
              type='checkbox'
              control={control}
            /> */}
              </Col>
              <Col xs='auto'>
                <Link className='fs--1 font-light' to={Navigation.RESET_PASSWORD}>
                  {t('Login.link.forgotPassword')}
                </Link>
              </Col>
            </Row>
            <FormGroup>
              <Button
                color='primary'
                block
                className='mt-3'
                isDisabled={isLoading}
                isLoading={isLoading}
              >
                {t('Login.button.logIn')}
              </Button>
            </FormGroup>
          </Form>
        </>
      )}
    </>
  );
};

export default Login;
