import React, { FC } from 'react';

import { Heading } from 'components';

const SettingsHeader: FC = () => (
  <Heading
    headingLevel='p'
    className={`flex flex-1  items-center text-2xl my-4 ml-4 font-semibold`}
  >
    Profile
  </Heading>
);

export default SettingsHeader;
