import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect } from 'react';
import Cards from 'react-credit-cards-2';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/order
import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  FormText,
} from 'reactstrap';

import 'react-credit-cards-2/dist/es/styles-compiled.css';

import { CardInfo } from 'data/model';
import usePayments from 'data/usePayments';
import { Button } from 'components/Buttons';
import { createCreditCardSchema } from 'pages/ClaimWristband/hooks/useClaimWristbandForm';
import AddCreditCardInputs from 'components/AddCard/components/AddCardInputs/AddCreditCardInputs';
import useEventUri from 'hooks/useEventUri';

export type creditCardInfo = {
  cardNumber: string;
  cardHolderName: string;
  expiryDate: string;
  cvv: string;
};

export interface AddPaymentMethodModalProps {
  toggle: () => void;
  modal: boolean;
  onClosed?: () => void;
  instantPay?: boolean;
  autoTopUp?: boolean;
  toggleAll?: () => void;
  instantPayCallback?: (cardInfo: CardInfo) => void;
  addCard?: boolean; // If true card will automatically save payment method
}

/**
 * AddPaymentMethodModal component for adding a payment method
 * is meant to be a standalone component that can be used anywhere to add payment method
 * To use this modal you must have state in place to handle the modal being open or closed within the parent component
 * ex:
 * import 'bootstrap/dist/css/bootstrap.min.css';
 * ...
 * const [modal, setModal] = useState(false);
 * const toggle = () => setModal(!modal);
 *
 * Note: This component has been extended to support instantPay and autoTopUp
 * instantPay: boolean - if true, the modal will present a save card checkbox
 * autoTopUp: boolean - if true, the modal will present a save card checkbox and the checkbox will be checked by default
 * @param {AddPaymentMethodModalProps} { toggle, modal }
 * @returns {JSX.Element}
 */
function AddPaymentMethodModal({
  toggle,
  modal,
  instantPay = false,
  autoTopUp = false,
  instantPayCallback,
  addCard = false,
}: AddPaymentMethodModalProps): JSX.Element {
  const { addPaymentMethod, status } = usePayments();
  const { t } = useTranslation();
  const eventUri = useEventUri();

  const [saveCard, setSaveCard] = useState(autoTopUp);
  const [paymentMethodAdded, setPaymentMethodAdded] = useState(false);

  const methods = useForm<creditCardInfo>({
    resolver: yupResolver(createCreditCardSchema(t)),
  });

  // If autoTopUp is set then we need to set the saveCard state to true
  useEffect(() => {
    setSaveCard(autoTopUp);
  }, [autoTopUp, setSaveCard]);

  useEffect(() => {
    const defaultValues = {} as creditCardInfo;
    defaultValues.cardNumber = '';
    defaultValues.cardHolderName = '';
    defaultValues.expiryDate = '';
    defaultValues.cvv = '';
    methods.reset(defaultValues);
  }, [methods]);

  const onSubmit = (data: creditCardInfo) => {
    // expirtyDate is of format MM/YY with optional / so we need to remove the / to get the month and year
    const dateWithoutSlashes = data.expiryDate.split('/').join('');
    const separatedDate = dateWithoutSlashes.match(/.{1,2}/g);
    if (!separatedDate || !separatedDate[0] || !separatedDate[1]) {
      console.log('invalid date'); // Should never happen
      return;
    }

    const newCardInfo: CardInfo = {
      number: data.cardNumber,
      cvv: data.cvv,
      month: separatedDate[0],
      year: '20' + separatedDate[1],
      full_name: data.cardHolderName,
      cardType: undefined,
      default: false,
      zip: undefined,
      country: undefined,
    };

    // Store card under user account if saveCard is checked
    if (saveCard || addCard) {
      eventUri && addPaymentMethod(newCardInfo, eventUri);
    }

    if (instantPay) {
      if (instantPayCallback) {
        instantPayCallback(newCardInfo);
      }
    }
    setPaymentMethodAdded(true);
  };

  const onErrors = (errors: unknown) => {
    console.log('onErrors', errors);
  };

  const onCancelClick = () => {
    methods.reset();
    toggle();
  };

  const handleSavedCardOnChange = (e: any) => {
    setSaveCard(e.target.checked);
  };

  useEffect(() => {
    // Close Screen once payment method added
    if (status !== 'loading' && paymentMethodAdded) {
      setPaymentMethodAdded(false);
      methods.reset();
      toggle();
    }
  }, [status, toggle, paymentMethodAdded, methods]);

  //Note: is instantPay is set they we need to add a save card checkbox
  // if autotop is set then save card checkbox should be checked by default and disabled
  // if save card is not checked then we should not retain the card
  // we must use different endpoint for instant pay without saving card
  // also if instantPay is set then we close the topup modal on 'confirm'
  return (
    <Modal isOpen={modal} toggle={toggle} backdrop='static'>
      <form onSubmit={methods.handleSubmit(onSubmit, onErrors)}>
        <ModalHeader>{t('addPaymentMethodModal.addPaymentMethod')}</ModalHeader>
        <ModalBody>
          <div className='min-h-[500px] w-full'>
            <Cards
              number={methods?.watch('cardNumber')}
              expiry={methods?.watch('expiryDate')}
              cvc={methods?.watch('cvv')}
              name={methods?.watch('cardHolderName')}
            />
            <AddCreditCardInputs
              autoTopUp={autoTopUp}
              setSaveCard={setSaveCard}
              toggle={toggle}
              methods={methods}
            />
            {/* Will only be displayed if instantPay prop is set*/}
            {!!instantPay && (
              <FormGroup check>
                {/* Handle user interaction here is setState not form */}
                <Input
                  type='checkbox'
                  checked={saveCard}
                  onChange={handleSavedCardOnChange}
                  disabled={autoTopUp}
                />
                <Label className='w-full' check>
                  {t('addPaymentMethodModal.saveCard')}
                </Label>
                <FormText>{t('addPaymentMethodModal.saveCardInfo')}</FormText>
              </FormGroup>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type='submit'
            className='w-24 btn btn-primary'
            disabled={paymentMethodAdded}
            isLoading={paymentMethodAdded}
          >
            {t('buttons.done')}
          </Button>
          <Button type='reset' onClick={onCancelClick} className='w-24 btn btn-secondary'>
            {t('buttons.cancel')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default AddPaymentMethodModal;
