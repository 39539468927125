import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { faCircleDollarToSlot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MenuLinkType } from 'components';
import { CalendarIcon, SettingsIcon, WalletIcon } from 'components/icons';
import MyTransactionsIcon from 'components/icons/MyTransactionsIcon';
import { Navigation } from 'navigation';
import {
  selectEventSelectedDetails,
  selectUserDetailedInfoLoading,
  selectUserHasContactInfo,
  useAppSelector,
} from 'store';
import RefundIcon from 'components/icons/RefundIcon';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

type ReturnType = {
  links: MenuLinkType[];
  parentSectionPath?: string;
};

type LinksType = 'main' | 'event';

export const useMenuLinks = (type?: LinksType): ReturnType => {
  const location = useLocation();
  const isMobile = useMediaQuery();

  const event = useAppSelector(selectEventSelectedDetails);
  const hasContactInfo = useAppSelector(selectUserHasContactInfo);
  const isContactInfoLoading = useAppSelector(selectUserDetailedInfoLoading);
  const { eventId } = useParams<{ eventId: string }>();

  const isLinkDisabled = !hasContactInfo || isContactInfoLoading;

  const links: ReturnType = useMemo(() => {
    const mainLinks = [
      {
        name: 'My Events',
        to: Navigation.EVENTS,
        isHidden: isLinkDisabled,
        icon: <CalendarIcon />,
      },
      {
        name: 'Profile',
        to: Navigation.SETTINGS,
        icon: <SettingsIcon />,
      },
    ];

    const eventLinks = [
      {
        name: 'Manage Funds',
        to: `events/${eventId}/manage-funds`,
        target: '',
        icon: <FontAwesomeIcon icon={faCircleDollarToSlot} />,
        isHidden: true, // !event || !event?.onlineTopupAllowed,
      },
      {
        name: 'Transactions',
        to: `events/${eventId}/transactions`,
        icon: <MyTransactionsIcon />,
      },
      {
        name: 'Refund Request',
        to: `events/${eventId}/refund-request`,
        target: '',
        icon: <RefundIcon />,
      },
      {
        name: 'Payment Methods',
        to: `events/${eventId}/payment-methods`,
        target: '',
        icon: <WalletIcon />,
        isHidden: !event || !event?.enableSavedPaymentMethod,
      },
    ].filter((link) => !link.isHidden);

    if (type === 'event') {
      return { links: eventLinks };
    }

    if (
      location.pathname === Navigation.EVENTS ||
      location.pathname.includes(Navigation.SETTINGS) ||
      location.pathname.includes('/manage-profile-info') ||
      location.pathname.includes('/add-wristband')
    ) {
      return { links: mainLinks };
    } else if (location.pathname.includes(`/events/${eventId}`)) {
      return {
        links: isMobile ? mainLinks : eventLinks,
        parentSectionPath: Navigation.EVENTS,
      };
    } else {
      return {
        links: isMobile ? mainLinks : eventLinks,
        parentSectionPath: Navigation.EVENTS,
      }; // Return eventLinks for any other routes that don't match
    }
  }, [isLinkDisabled, location.pathname, eventId, isMobile, type, event]);

  return links;
};
