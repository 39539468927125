import React, { ReactNode, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FiChevronsRight } from 'react-icons/fi';
import moment from 'moment';

import useLedger from 'data/useLedger';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { EventDetails, EventSummaryType } from 'data';
import { Navigation } from 'navigation';
import { getLinkWithParams, waitFor } from 'navigation/utils';
import { getDateFormatShort } from 'helpers/date/getDateFormat';
import useEventUri from 'hooks/useEventUri';
import { Spinner } from 'components';

const Cell = ({ className, children }: { className: string; children: ReactNode }) => (
  <div className={className}>{children}</div>
);

interface RoundedCardProps {
  title: string;
  description: string;
  onClick?: () => void;
  isLoading?: boolean;
  actionButton?: {
    label: string;
    onClick: () => void;
  };
}

const RoundedCard: React.FC<RoundedCardProps> = ({
  title,
  description,
  onClick,
  actionButton,
  isLoading,
}) => {
  const handleCardClick = () => {
    if (!actionButton && onClick) {
      onClick();
    }
  };

  return (
    <div
      onClick={handleCardClick}
      className={`flex flex-col justify-between bg-white rounded-lg p-3 mb-4 cursor-pointer hover:shadow-lg transition-shadow duration-300 ${
        actionButton ? 'cursor-default' : ''
      }`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex flex-col'>
          <span className='text-md font-semibold text-[#232E59]'>{title}</span>
          <span className='text-sm text-[#6F7FAF] mt-1'>{description}</span>
        </div>
        {isLoading && <Spinner color='gray' />}
        {!actionButton && !isLoading && (
          <FiChevronsRight size='24px' className='ml-4 self-center' />
        )}
      </div>
      {actionButton && !isLoading && (
        <Button
          color='secondary'
          onClick={(e) => {
            e.stopPropagation();
            actionButton.onClick();
          }}
          className='w-full mt-4 text-md px-4 py-2 rounded transition-colors duration-300'
        >
          {actionButton.label}
        </Button>
      )}
    </div>
  );
};

interface EventTableItemProps {
  uuid: string;
  eventDetails: EventDetails;
  onViewEventClick: (uuid: string) => void;
  eventSummary: EventSummaryType;
}

const EventTableItem: React.FC<EventTableItemProps> = ({
  uuid,
  eventDetails,
  eventSummary,
  onViewEventClick,
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [hasWristbandAssigned, setHasWristbandAssigned] = useState<boolean | null>(null);
  const { fetchEventWallet } = useLedger();
  const [isFetchingWallet, setIsFetchingWallet] = useState(true);
  const isMobile = useMediaQuery();

  const eventUri = useEventUri(uuid);

  const navigatedPage = eventDetails?.enableSavedPaymentMethod
    ? Navigation.PAYMENT_METHODS
    : Navigation.TRANSACTIONS;

  useEffect(() => {
    const fetchWalletForEvent = async () => {
      if (eventUri && hasWristbandAssigned !== null) {
        try {
          setIsFetchingWallet(true);
          const walletInfo: any = await fetchEventWallet(eventUri!);
          setHasWristbandAssigned(
            walletInfo && walletInfo.rfid && walletInfo.rfid !== '' ? true : false,
          );
        } catch (error) {
          setHasWristbandAssigned(false);
        } finally {
          setIsFetchingWallet(false);
        }
      } else {
        setHasWristbandAssigned(false);
      }
    };

    fetchWalletForEvent();
  }, [fetchEventWallet, eventUri, hasWristbandAssigned, searchParams]);

  useEffect(() => {
    // if there is no initialized wallet yet for this event - we should fetch it until response is received
    const fetchWalletInfo = async () => {
      if (searchParams.get('fetchWalletFor') === uuid && eventUri) {
        try {
          const walletInfo = await waitFor(fetchEventWallet, [eventUri!], 10000, 15);
          setHasWristbandAssigned(
            walletInfo && walletInfo.rfid && walletInfo.rfid !== '' ? true : false,
          );
        } catch (error) {
          console.error('Unable to retrieve wallet info for ', uuid);
        }
      }
    };

    fetchWalletInfo();
  }, [searchParams, uuid, eventUri, fetchEventWallet]);

  const renderActionButton = (): ReactNode => {
    if (!eventDetails) {
      return 'Loading...';
    }

    if (isFetchingWallet)
      return (
        <div className='flex justify-center'>
          <Spinner size={6} />
        </div>
      );

    if (hasWristbandAssigned === null) return 'Loading...';

    if (hasWristbandAssigned) {
      return (
        <Link
          to={getLinkWithParams(navigatedPage, { eventId: uuid })}
          className={`font-normal py-1 rounded text-medium text-intelli-secondary ${
            isMobile ? '' : 'px-3'
          }`}
          onClick={() => onViewEventClick(uuid)}
        >
          {isMobile ? (
            <Button
              color='primary'
              size={`${isMobile ? 'lg' : 'sm'}`}
              onClick={() => onViewEventClick(uuid)}
            >
              View
            </Button>
          ) : (
            ' View'
          )}
        </Link>
      );
    }

    if (!hasWristbandAssigned) {
      return (
        <Link
          to={`${Navigation.CLAIM_WRISTBAND}?folioId=${eventUri}&securityIdOnly=${
            eventDetails.claimCredentials === 'Security Code Only'
          }&ledgerId=${eventDetails?.distributedLedgerId}`}
          className={`text-intelli-secondary font-normal py-1 rounded text-medium ${
            isMobile ? '' : 'px-3'
          }`}
        >
          {isMobile ? (
            <Button
              color='secondary'
              className={`btn btn-primary btn-sm w-full md:w-auto ${isMobile && 'py-4'}`}
            >
              Add Wristband
            </Button>
          ) : (
            'Add Wristband'
          )}
        </Link>
      );
    }
  };

  return isMobile ? (
    <RoundedCard
      title={eventSummary.name.en}
      onClick={
        hasWristbandAssigned
          ? () => {
              navigate(getLinkWithParams(navigatedPage, { eventId: uuid }));
            }
          : undefined
      }
      description={getDateFormatShort(eventSummary.startDate)}
      isLoading={isFetchingWallet}
      actionButton={
        hasWristbandAssigned
          ? undefined
          : {
              label: 'Add Wristband',
              onClick: () =>
                navigate(
                  `${Navigation.CLAIM_WRISTBAND}?folioId=${eventUri}&securityIdOnly=${
                    eventDetails.claimCredentials === 'Security Code Only'
                  }&ledgerId=${eventDetails?.distributedLedgerId}`,
                ),
            }
      }
    />
  ) : (
    <div
      key={uuid}
      className={`${
        isMobile ? 'block border-b-4' : 'flex border-b'
      } justify-between items-center px-6 py-3`}
    >
      <Cell
        className={`${
          isMobile ? 'border-b font-bold' : 'flex-1 text-center font-normal'
        }  text-custom-primary text-base`}
      >
        {eventSummary.name.en}
      </Cell>
      {isMobile ? (
        <Cell className='flex-1 Font-light text-sm text-custom-secondary pt-3'>START DATE</Cell>
      ) : null}
      <Cell
        className={`${
          isMobile ? 'pb-3 pt-1' : 'flex-1 text-center text-custom-secondary'
        } font-normal`}
      >
        {moment(eventSummary.startDate).format('MMMM DD, YYYY')}
      </Cell>
      <Cell className={`${isMobile ? 'pb-3 pt-1' : 'flex-1 text-center'} font-light`}>
        {renderActionButton()}
      </Cell>
    </div>
  );
};

export default EventTableItem;
