import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Button } from 'reactstrap';

import { FlexBox, Switch, TextField } from 'components';
import usePayments from 'data/usePayments';
import { getCreditCardIconByType, replaceSpreedlyHideString } from 'helpers/creditCardUtils';
import useEventUri from 'hooks/useEventUri';

const PaymentMethodDetails: FC = () => {
  const navigate = useNavigate();
  const { pmId } = useParams();

  const {
    paymentMethods,
    getAllPaymentMethods,
    isLoading,
    changeDefaultPayment,
    deletePaymentMethodByUuid,
  } = usePayments();
  const eventUri = useEventUri();

  const paymentMethod = paymentMethods?.find((pm) => pm.uuid === pmId);

  const setAsDefault = () => {
    if (!paymentMethod?.default) {
      changeDefaultPayment(paymentMethod!.uuid!, eventUri);
    }
  };

  const handleDelete = async () => {
    await deletePaymentMethodByUuid(paymentMethod!.uuid, eventUri);
    await getAllPaymentMethods();
    navigate(-1);
  };

  return (
    <div className='px-4 pt-2 bg-white'>
      <FlexBox
        className={classNames('flex mt-[4px] items-center leading-10', {
          '!justify-start': true,
        })}
      >
        <div className='pl-1 pr-4' onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faArrowLeft} size='lg' />
        </div>

        <p className='text-[19px] font-semibold'>Card Details</p>
      </FlexBox>

      <div className='my-4'>
        <FontAwesomeIcon
          color='#1A1F71'
          size='2xl'
          icon={getCreditCardIconByType(paymentMethod?.meta.cardType || 'visa')}
        />
      </div>

      <div className='pb-3'>
        <TextField
          value={replaceSpreedlyHideString(paymentMethod?.info.spreedlyPaymentMethod.number || '')}
          label='Card Number'
          isDisabled
        />
      </div>

      <div className='py-2'>
        <TextField
          value={`${String(paymentMethod?.info.spreedlyPaymentMethod.month).padStart(
            2,
            '0',
          )}/${paymentMethod?.info.spreedlyPaymentMethod.year}`}
          label='Expiration Date'
          isDisabled
        />
      </div>

      <div className='py-8 flex justify-between'>
        <label className='text-lg font-semibold pr-2 pt-1'>Default payment method:</label>
        <Switch isChecked={paymentMethod?.default} isLoading={isLoading} onChange={setAsDefault} />
      </div>

      <div className='fixed bottom-0 left-0 w-full bg-white shadow-lg p-4 rounded-2xl space-y-4'>
        <Button
          color='secondary'
          className='w-full rounded-lg text-lg font-bold py-2'
          onClick={handleDelete}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default PaymentMethodDetails;
