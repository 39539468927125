import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import valid from 'card-validator';
import { TFunction } from 'i18next';

import { validateCardExpiryDate } from 'components/AddCard/helpers';

const createClaimWristbandSchema = (isSecurityIdOnly: boolean) => {
  return yup.object().shape({
    ...(isSecurityIdOnly
      ? {
          securityId: yup
            .string()
            .matches(
              /^[A-Z\d]+$/,
              'Your Security ID must be 4 to 7 character alphanumeric in UPPERCASE',
            )
            .required('Security ID is required')
            .min(4)
            .max(7),
        }
      : {
          securityId: yup
            .string()
            .matches(/^\d+$/, 'Must be only digits')
            .required('Security ID is required')
            .max(7)
            .min(4),
          wristbandId: yup.string().required('Wristband/Card UID is required'),
        }),
  });
};

export const useClaimWristbandForm = (isSecurityIdOnly: boolean) =>
  useForm({
    resolver: yupResolver(createClaimWristbandSchema(isSecurityIdOnly)),
    defaultValues: {
      wristbandId: '',
      securityId: '',
    },
    mode: 'all',
  });

export const createCreditCardSchema = (t: TFunction<'translation', undefined>) =>
  yup.object().shape({
    cardNumber: yup
      .string()
      .required(t('addPaymentMethodModal.errorCreditCardIsRequired') as string)
      .test(
        'test-number', // this is used internally by yup
        t('addPaymentMethodModal.errorCreditCardNumber') as string, //validation message
        (value) => valid.number(value).isValid,
      ), // return true false based on validation
    cardHolderName: yup
      .string()
      .required(t('addPaymentMethodModal.errorCardholderNameRequired') as string)
      .matches(/^[a-zA-Z\s]*$/, t('addPaymentMethodModal.errorOnlyLettersAndSpaces') as string),
    expiryDate: yup
      .string()
      .required(t('addPaymentMethodModal.errorExpiryDateRequired') as string)
      .matches(
        /^(0[1-9]|1[0-2])\/?(\d{4}|\d{2})$/,
        t('addPaymentMethodModal.errorInvalidExpiryDateFormat') as string,
      )
      .test('is-not-expired', 'Expiry date is expired', validateCardExpiryDate),
    cvv: yup
      .string()
      .required(t('addPaymentMethodModal.errorCVVRequired') as string)
      .matches(/^\d{3,4}$/, t('addPaymentMethodModal.errorCVVInvalid') as string),
  });
