/* eslint-disable import/order, import/named */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AuthProvider } from 'hooks/Firebase';
import { DataProvider } from 'data/ContentProvider';
import { Routing } from 'navigation';
import { ReduxProvider } from 'store';
import { FlagProvider } from '@unleash/proxy-client-react';
import { HelmetProvider } from 'react-helmet-async';
import 'app.css';

import ReactGa from 'react-ga4';
import ContentSecurityPolicyController from 'helpers/ContentSecurityPolicyController';

const featureFlagConfig = {
  url: 'https://unleash.intellitix.com/api/frontend',
  clientKey: '*:development.26677e8264ac3d723671a334c31e87aaa32d41331a1b29087693d841',
  refreshInterval: 3600,
  appName: 'Patron Web',
};

/**
 * Safety check to avoid app crash in case env is not defined
 * Google analytics doesn't allow for null or '' when initializing
 * Analytics will only be collected as longs as browser doesn't have ad blocker enabled such as "uBlock Origin"
 **/
if (
  process.env.REACT_APP_ENV === 'production' &&
  process.env.REACT_APP_ENV_GOOGLE_ANALYTICS !== '' &&
  process.env.REACT_APP_ENV_GOOGLE_ANALYTICS !== undefined
) {
  ReactGa.initialize(process.env.REACT_APP_ENV_GOOGLE_ANALYTICS);
}

const App = () => (
  <FlagProvider config={featureFlagConfig}>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}>
      <BrowserRouter>
        <ReduxProvider>
          <AuthProvider>
            <HelmetProvider>
              <DataProvider>
                <ContentSecurityPolicyController />
                <Routing />
                <ToastContainer autoClose={5000} />
              </DataProvider>
            </HelmetProvider>
          </AuthProvider>
        </ReduxProvider>
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  </FlagProvider>
);

export default App;
