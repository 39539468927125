export type NavigationParams = {
  [Navigation.TRANSACTIONS]: { eventId: string };
  [Navigation.EVENT_DETAILS]: { eventId: string };
  [Navigation.EVENT_BALANCE]: { eventId: string };
  [Navigation.REFUND_REQUEST]: { eventId: string };
  [Navigation.TRANSACTION_DETAILS]: {
    eventId: string;
    transactionId: string;
  };
  [Navigation.PAYMENT_METHOD_DETAILS]: {
    eventId: string;
    pmId: string;
  };
  [Navigation.PAYMENT_METHODS]: {
    eventId: string;
  };
  [Navigation.MANAGE_FUNDS]: { eventId: string };
};

export enum Navigation {
  AUTH = '/auth',
  LOGIN = '/login',
  RESET_PASSWORD = '/reset-password',
  SIGN_UP = '/sign-up',
  PROFILE = '/profile',
  CARDS = '/cards',
  ADD_CARD = '/add-card',
  MY_TRANSACTIONS = '/my-transactions',
  HOME = '/',
  EVENTS = '/events',
  TRANSACTIONS = '/events/:eventId/transactions',
  EVENT_DETAILS = '/events/:eventId/details',
  EVENT_BALANCE = '/events/:eventId/event-balance',
  MANAGE_FUNDS = '/events/:eventId/manage-funds',
  PAYMENT_METHODS = '/events/:eventId/payment-methods',
  REFUND_REQUEST = '/events/:eventId/refund-request',
  TRANSACTION_DETAILS = '/events/:eventId/transactions/:transactionId/details',
  PAYMENT_METHOD_DETAILS = '/events/:eventId/payment-methods/:pmId/details',
  SETTINGS = '/settings',
  USER_PROFILE = '/settings/user-profile',
  SUPPORT = 'https://intellitixguest.zendesk.com/hc/en-us',
  TERMS = 'https://intellitix.com/about/legal/privacy-policy',
  CLAIM_WRISTBAND = '/add-wristband',
  RESET_WITH_PASSWORD = '/reset-with-password', // Reset Request Form (From Login Page)
  SIGN_OUT = '/sign-out',
  MANAGE_PROFILE = '/manage-profile-info',
  AUTH_ACTION = '/public/action',
}
