import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Input, Popover, PopoverBody } from 'reactstrap';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import usePayments from 'data/usePayments';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import useEventUri from 'hooks/useEventUri';

interface PaymentMethodRowDisplayProps {
  id: string;
  number: string;
  expiryMonth: string;
  expiryYear: number;
  defaultValue: boolean;
  cardTypeIcon: IconProp;
  setDefaultCallback: (id: string, eventUri: any) => void;
  onSelect?: () => void;
  deleteMethodCallback?: (id: string, eventUri: any) => void;
  showEllipsis?: boolean;
}

const PaymentMethodRowDisplay = ({
  id,
  number,
  expiryMonth,
  expiryYear,
  defaultValue,
  cardTypeIcon,
  onSelect,
  deleteMethodCallback,
  setDefaultCallback,
  showEllipsis = true,
}: PaymentMethodRowDisplayProps) => {
  const [deleting, setDeleting] = useState(false);
  const isMobile = useMediaQuery();
  const { status } = usePayments();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const eventUri = useEventUri();

  const togglePopover = (e: React.MouseEvent) => {
    e.preventDefault();
    setPopoverOpen((prev) => !prev);
  };

  const isDeleting = status === 'loading' && deleting;

  useEffect(() => {
    if (status !== 'loading' && deleting) {
      setDeleting(false);
    }
  }, [status, deleting]);

  const handleDelete = () => {
    if (deleteMethodCallback) {
      deleteMethodCallback(id, eventUri);
      setDeleting(true);
      setPopoverOpen(false);
    }
  };

  const handleSetAsDefault = async () => {
    setDefaultCallback(id, eventUri);
    setPopoverOpen(false);
  };

  const renderPopoverContent = () => (
    <PopoverBody className='p-0 cursor-pointer'>
      <div className={`text-xs p-2 ${isDeleting ? 'opacity-50' : ''}`} onClick={handleDelete}>
        Delete
      </div>
      <div className='text-xs p-2' onClick={handleSetAsDefault}>
        Set as Default
      </div>
    </PopoverBody>
  );

  const renderPaymentMethodCard = () => (
    <div className='flex justify-between w-full items-center p-2'>
      <div className='flex'>
        <Input type='radio' name='default' defaultChecked={defaultValue} onChange={onSelect} />
        <div className='flex-shrink-0 px-2 pl-0'>
          <FontAwesomeIcon icon={cardTypeIcon} className='pr-2.5' size='xl' />
        </div>
        <div>{number}</div>
      </div>
      <div className='flex gap-2'>
        {defaultValue && <div className='bg-gray-100 text-xs p-1 rounded-full px-2'>Default</div>}
        <div className='text-custom-secondary'>
          {expiryMonth}/{expiryYear}
          {showEllipsis && (
            <>
              <FontAwesomeIcon
                icon={faEllipsisV}
                id={`popover-${id}`}
                className='text-custom-primary pl-4'
              />
              <Popover
                placement='bottom-end'
                isOpen={popoverOpen}
                target={`popover-${id}`}
                toggle={togglePopover}
                fade={false}
                trigger='legacy'
                hideArrow
              >
                {renderPopoverContent()}
              </Popover>
            </>
          )}
        </div>
      </div>
    </div>
  );

  const renderDesktopPaymentMethodCard = () => (
    <div className='flex items-center justify-between py-3'>
      <div className='flex items-center'>
        <Input type='radio' name='default' defaultChecked={defaultValue} onChange={onSelect} />
        <div className='flex-shrink-0 p-2 w-[60px]'>
          <FontAwesomeIcon icon={cardTypeIcon} className='mr-4 pr-2.5 pl-1.5' size='xl' />
        </div>
        <div className='flex-1 p-2'>{number}</div>
      </div>
      <div className='flex'>
        <div className='p-2'>
          {defaultValue && <div className='bg-gray-100 text-xs p-1 rounded-full px-2'>Default</div>}
        </div>
        <div className='p-2 text-custom-secondary text-sm'>
          Expires {expiryMonth}/{expiryYear}
          {showEllipsis && (
            <>
              <FontAwesomeIcon
                icon={faEllipsisV}
                id={`popover-${id}`}
                className='text-custom-primary pl-4 cursor-pointer'
              />
              <Popover
                placement='bottom-end'
                className='p-0'
                isOpen={popoverOpen}
                target={`popover-${id}`}
                toggle={togglePopover}
                fade={false}
                trigger='legacy'
                hideArrow
              >
                {renderPopoverContent()}
              </Popover>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return isMobile ? renderPaymentMethodCard() : renderDesktopPaymentMethodCard();
};

export default PaymentMethodRowDisplay;
