import { BalanceTransaction } from './types';

import { formatDateToMed } from 'helpers';
import { formatCurrency } from 'helpers/currency';

export const groupTransactionsByDay = (transactions: BalanceTransaction[]) => {
  const groupedTransactions: { [key: string]: BalanceTransaction[] } = {};

  transactions.forEach((transaction) => {
    const date = transaction.date;
    if (!groupedTransactions[date]) {
      groupedTransactions[date] = [];
    }
    groupedTransactions[date].push(transaction);
  });

  return groupedTransactions;
};

export const isPositiveAmount = (amount: string) => amount.includes('+');

export const mapRefundTransactionToBalanceTransaction = (
  refundTransaction: any,
  currency: any,
): BalanceTransaction => {
  return {
    id: refundTransaction.uuid,
    vendor: 'Intellitix',
    date: formatDateToMed(refundTransaction.completedOn),
    createdOn: refundTransaction.completedOn,
    transaction: 'Refund',
    amount: formatCurrency(refundTransaction.refundTotalAmount / 100, currency),
    isDebit: false,
  };
};
