import axios from 'axios';
import { DomainContactInfo, Identity } from 'jwt-cert-sdk';
import { useEffect, useState } from 'react';

import { Config } from '../Config';

import { ClientConfig, SecuredApi } from './Api';

import { ClaimConfigType, EventType } from 'data';
import { paths } from 'data/constants';

interface EventsApi {
  fetchEvent: (eventId: string) => Promise<EventType>;
  fetchUpcomingEvents: () => Promise<any>;
  getClaimConfigForEvent: (eventId: string) => Promise<ClaimConfigType>;
}

export class EventApiImpl extends SecuredApi implements EventsApi {
  authtokenTTL = 180;
  rfidSearch = '';
  clientConfig = (): ClientConfig => {
    return {
      baseURL: Config.EVENTS,
      timeout: 15000,
    };
  };

  getClaimConfigForEvent = async (eventId: string): Promise<ClaimConfigType> => {
    const response = await this.client.get(paths.getClaimConfigForEvent(eventId));
    return response.data;
  };

  fetchEvent = async (eventId: string): Promise<EventType> => {
    const response = await this.client.get(paths.getEvent(eventId));
    return response.data.entry;
  };

  fetchUpcomingEvents = async (): Promise<{ entries: EventType[] }> => {
    let allEntries: any[] = [];
    let nextPageToken: string | null = null;
    let hasMore = true;

    do {
      const response: any = await this.client.get(paths.fetchUpcomingEvents(nextPageToken!));
      const { entries, page } = response.data;

      allEntries = [...allEntries, ...entries];
      nextPageToken = page.nextPageToken;
      hasMore = page.hasMore;
    } while (hasMore);

    return { entries: allEntries as EventType[] };
  };
}

let staticServiceIdentity:
  | null
  | Promise<Identity<DomainContactInfo> | null>
  | Identity<DomainContactInfo> = null;
export const useEventServiceIdentity = () => {
  const [serviceIdentity, setServiceIdentity] = useState<Identity<DomainContactInfo> | null>(null);
  useEffect(() => {
    if (staticServiceIdentity === null)
      staticServiceIdentity = axios
        .request({ method: 'GET', baseURL: Config.EVENTS, url: paths.getServiceIdentity() })
        .then(
          (response) => {
            return (staticServiceIdentity = response.data as Identity<DomainContactInfo>);
          },
          (err) => {
            console.error(err);
            return null;
          },
        );

    if (staticServiceIdentity && staticServiceIdentity instanceof Promise)
      staticServiceIdentity.then((idt) => setServiceIdentity(idt));
    else setServiceIdentity(staticServiceIdentity);
  }, []);
  return serviceIdentity;
};

export default EventApiImpl;
