import React, { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { SideBar } from 'components';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import Breadcrumbs from 'hooks/BreadCrumbs';

const Layout: FC = () => {
  const isMobile = useMediaQuery();
  const { pathname } = useLocation();

  return (
    <div className='md:flex font-LRegular'>
      {!(isMobile && pathname === '/manage-profile-info') && <SideBar />}
      <div className='flex-1 md:ml-[226px]'>
        {!isMobile && <Breadcrumbs />}
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
