import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import TopUpTransactionDetails from './TopUpTransactionDetails';
import PurchaseTransactionDetails from './PurchaseTransactionDetails';

import { Button, FlexBox, Loader, Spinner } from 'components';
import { Order, ProductKind, useData, useOrders } from 'data';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { TopUpType, isDefaultTopUpType } from 'helpers/topUpTypes';
import { selectEventCurrency } from 'store';

const TransactionDetails: FC = () => {
  const { transactionId } = useParams();
  const { sendEmailReceipt, isOrdersLoading } = useOrders();
  const { securedApi } = useData();
  const navigate = useNavigate();
  const isMobile = useMediaQuery();
  const currency = useSelector(selectEventCurrency);

  const [order, setOrder] = useState<Order | undefined>();
  const [vendorName, setVendorName] = useState<string>('');
  const [productKind, setProductKind] = useState<ProductKind | undefined>();

  useEffect(() => {
    const fetchOrder = async () => {
      const order = (await securedApi?.OrdersApi.getOrderById(transactionId!)) || undefined;

      if (!order) {
        console.error(`Transaction[${transactionId}] (order) not found`);
        return;
      }

      if (order.folioId && order.products[0]?.productKind !== 'token') {
        const vendorInfo = await securedApi?.OrdersApi.getVendorByFolioId(order.folioId!);

        const vendorName =
          order.misc?.sessionInfo?.locationName || vendorInfo?.vendorName || 'Intellitix';

        setVendorName(vendorName);
      }

      setOrder(order);
      setProductKind(order.products[0]?.productKind);
    };

    fetchOrder();
  }, [transactionId, securedApi]);

  if (!order) {
    return (
      <div className='flex items-center justify-center h-screen'>
        <Loader />
      </div>
    );
  }

  const topUpType = order.topupTypeUuid || '';

  const isTopUpTransaction = productKind === 'token';

  const shouldShowEmailReceiptButton =
    !isTopUpTransaction ||
    (isDefaultTopUpType(topUpType) &&
      topUpType !== TopUpType.ADMIN_TOPUP &&
      topUpType !== TopUpType.CUSTOM_TOPUP);

  return (
    <div className={classNames('px-4 pt-2 ', isMobile && 'bg-white')}>
      <div className='mb-20'>
        <FlexBox
          className={classNames('flex mt-[4px] items-center leading-10', {
            '!justify-start': isMobile,
          })}
        >
          {isMobile && (
            <div className='pl-1 pr-4' onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} size='lg' />
            </div>
          )}
          <p className='text-[19px] font-semibold'>Transaction Details</p>
          <div>
            {!isMobile && (
              <>
                <Button className='btn btn-normal btn-secondary mr-2' onClick={() => navigate(-1)}>
                  Back
                </Button>
                {shouldShowEmailReceiptButton && (
                  <Button
                    className='btn btn-normal btn-secondary'
                    onClick={() => sendEmailReceipt(order.uuid)}
                    isLoading={isOrdersLoading}
                  >
                    Email Receipt
                  </Button>
                )}
              </>
            )}
          </div>
        </FlexBox>

        {isTopUpTransaction ? (
          <TopUpTransactionDetails order={order} />
        ) : (
          <PurchaseTransactionDetails order={order} vendorName={vendorName} currency={currency} />
        )}
      </div>
      {isMobile && (
        <div className='fixed bottom-0 left-0 w-full bg-white shadow-lg p-4 rounded-2xl space-y-2'>
          <Button
            className={classNames('w-full btn-primary rounded-lg py-2', {
              'bg-intelli-primaryNew': isOrdersLoading,
            })}
            disabled={isOrdersLoading}
            onClick={() => sendEmailReceipt(order.uuid)}
          >
            {isOrdersLoading ? <Spinner size={6} color='gray' /> : 'Email Receipt'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TransactionDetails;
