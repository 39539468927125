import React from 'react';

import securityIdOnly from 'assets/images/securityIdOnly.jpeg';
import wristband from 'assets/images/wristband.png';
import { Button, ControlledTextField } from 'components';

interface WristbandFormProps {
  onSubmit: any;
  isMobile: boolean;
  control: any;
  isValid: boolean;
  isSecurityIdOnly: boolean;
  isLoading: boolean;
  preparingWallet: boolean;
}

function WristbandForm({
  onSubmit,
  isMobile,
  control,
  isValid,
  isSecurityIdOnly,
  isLoading,
  preparingWallet,
}: WristbandFormProps) {
  return (
    <div className='md:flex md:justify-between shadow-md rounded'>
      <div
        className={
          isMobile ? 'hidden' : 'md:w-2/5 md:pr-8 bg-gray-50 rounded p-8 flex flex-col items-center'
        }
      >
        <img
          src={isSecurityIdOnly ? securityIdOnly : wristband}
          alt='intellitix-wristband-reference'
          className={`w-full rounded ${isMobile && 'hidden'} max-w-[320px] flex`}
        />
        <p className='text-[#6F7FAF] pt-6 hidden md:block'>
          {isSecurityIdOnly
            ? ''
            : '*ID numbers will appear on a wristband, badge, or card. Not all ID numbers will be found in the same location.'}
        </p>
      </div>
      <div className={`md:w-3/5 bg-white ${isMobile ? 'px-4 pb-[80px]' : 'py-4 px-8'} rounded`}>
        <form onSubmit={onSubmit}>
          <div className='flex justify-between flex-col gap-8'>
            <div className='flex flex-col'>
              <div className={`${isMobile ? 'hidden' : ''}`}>
                <p className={`text-[32px] text-[#232E59] font-bold pb-6 text-xl md:text-3xl`}>
                  Wristband Details
                </p>
                <p className='text-[#6F7FAF] pb-6'>
                  Fill out the following information to add your wristband or card.
                </p>
              </div>
              {isSecurityIdOnly ? (
                <ControlledTextField
                  labelClassName='text-base'
                  control={control}
                  label={`Security ID  ${
                    isMobile ? '' : '(7 alphanumeric code on the back of your wristband )'
                  }`}
                  fieldName='securityId'
                  placeholder={isMobile ? '4-7 digit code' : ''}
                />
              ) : (
                <>
                  <div className='pb-3'>
                    <ControlledTextField
                      labelClassName='text-base'
                      control={control}
                      label={`Security ID  ${isMobile ? '' : '(4-7 digit code)'}`}
                      fieldName='securityId'
                      placeholder={isMobile ? '4-7 digit code' : ''}
                    />
                  </div>
                  <div className='pb-3'>
                    <ControlledTextField
                      labelClassName='text-base'
                      control={control}
                      upperCase
                      label={`Wristband/Card UID ${
                        isMobile
                          ? ''
                          : '(14-16 character code containing a combination of numbers and letters)'
                      }`}
                      fieldName='wristbandId'
                      placeholder={isMobile ? '14-16 character combo of numbers & letters' : ''}
                    />
                  </div>
                </>
              )}
            </div>
            <div
              className={`${isMobile && 'hidden'} flex flex-col md:flex-row md:justify-end gap-3`}
            >
              <div className={`md:hidden block ${!isMobile ? 'h-[24px]' : ''}`} />
              <Button
                isLoading={isLoading}
                disabled={!isValid}
                loadingText={preparingWallet ? 'Preparing Account' : ''}
                type='submit'
                className={`btn btn-primary btn-sm w-full md:w-auto ${
                  isMobile && 'py-4'
                } disabled:color-[#6F7FAF] disabled:bg-[#E5E7EB] disabled:border-[#E5E7EB]`}
              >
                Continue
              </Button>
            </div>
          </div>

          <div
            className={`block md:hidden rounded justify-center mx-auto mt-[15px] ${
              isMobile ? '' : 'shadow-md p-[4px]'
            }`}
          >
            <div className='rounded overflow-hidden flex justify-center'>
              <img
                src={isSecurityIdOnly ? securityIdOnly : wristband}
                alt='intellitix-wristband-reference'
                className='w-full rounded max-w-[320px]'
              />
            </div>
            <p className='text-[#6F7FAF] md:hidden p-6 text-sm'>
              *ID numbers will appear on a wristband, badge, or card. Not all ID numbers will be
              found in the same location.
            </p>
          </div>

          {isMobile ? (
            <div className='fixed bottom-0 left-0 w-full bg-white shadow-lg p-4 rounded-2xl space-y-2'>
              <Button
                className='w-full btn-primary h-[48px] rounded-lg py-2 disabled:color-[#6F7FAF] disabled:bg-[#E5E7EB]'
                type='submit'
                isLoading={isLoading}
                disabled={!isValid}
                loadingText={preparingWallet ? 'Preparing Account' : ''}
              >
                Add Wristband
              </Button>
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
}

export default WristbandForm;
