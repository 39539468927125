import React, { FC, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Button } from 'reactstrap';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';

import PaymentMethodsTable from 'components/layout/PaymentMethodsTable';
import usePayments from 'data/usePayments';
import 'react-toastify/dist/ReactToastify.css';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { PaymentMethod } from 'data';
import { replaceSpreedlyHideString } from 'helpers/creditCardUtils';
import { AddPaymentMethodModal, Heading } from 'components';
import { getLinkWithParams } from 'navigation/utils';
import { Navigation } from 'navigation';

const PaymentMethods: FC = () => {
  const { getAllPaymentMethods, paymentMethods, deletePaymentMethodByUuid, changeDefaultPayment } =
    usePayments();
  const { eventId } = useParams();
  const [showAddMethodModal, setShowModal] = useState(false);
  const toggleAddMethodModal = () => setShowModal(!showAddMethodModal);

  const isMobile = useMediaQuery();

  useEffect(() => {
    getAllPaymentMethods();
  }, [getAllPaymentMethods]);

  return (
    <>
      <ToastContainer />
      {isMobile ? (
        <div className='flex flex-col h-screen'>
          <div className='overflow-auto'>
            <div className='text-custom-primary text-xl font-bold pb-2'>Add Payment Method</div>
            <div className='text-custom-secondary text-md pb-4'>
              Attaching a payment method allows you to spend directly from your card without needing
              to top-up funds to your Event Balance.
            </div>
            {paymentMethods.length > 0 ? (
              paymentMethods.map((card: PaymentMethod) => (
                <React.Fragment key={card.uuid}>
                  <Link
                    to={`${getLinkWithParams(Navigation.PAYMENT_METHOD_DETAILS, {
                      eventId: eventId!,
                      pmId: card.uuid,
                    })}?hideLayoutHeader=true`}
                  >
                    <div
                      className={`flex items-center justify-between px-3 py-2 rounded-md mb-4 shadow-md cursor-pointer transition-shadow duration-200 ${'bg-white border-gray-200 hover:shadow-md'}`}
                    >
                      <div className='flex flex-col'>
                        <div className='flex items-center space-x-2'>
                          <span className='text-lg font-bold text-[#232E59]'>VISA</span>
                          <span className='text-lg font-semibold text-custom-secondary mt-2'>
                            {replaceSpreedlyHideString(card.info.spreedlyPaymentMethod.number)}
                          </span>
                        </div>
                        <span className='text-sm text-gray-500'>
                          {String(card.info.spreedlyPaymentMethod.month).padStart(2, '0')}/
                          {card.info.spreedlyPaymentMethod.year}
                        </span>
                      </div>
                      {card.default && (
                        <div className='bg-gray-200 text-xs rounded-xl px-2 py-1'>Default</div>
                      )}
                      <FontAwesomeIcon icon={faChevronRight} color='#C7C7C7' />
                    </div>
                  </Link>
                </React.Fragment>
              ))
            ) : (
              <div className='flex flex-col justify-center items-center'>
                <Heading headingLevel='h3' className='text-lg text-custom-primary font-normal pt-2'>
                  No payment methods have been added yet
                </Heading>
                <Heading
                  headingLevel='h2'
                  className='text-base text-custom-secondary font-normal pt-2'
                >
                  Add your payment method to start making payments.
                </Heading>
              </div>
            )}
          </div>
          <div className='fixed bottom-0 left-0 w-full bg-white shadow-lg p-4 rounded-2xl space-y-4'>
            <Button
              color='primary'
              className='w-full rounded-lg text-lg font-bold py-2'
              onClick={toggleAddMethodModal}
            >
              Add Payment Method
            </Button>
          </div>
          <AddPaymentMethodModal
            toggle={toggleAddMethodModal}
            modal={showAddMethodModal}
            onClosed={toggleAddMethodModal}
            addCard
          />
        </div>
      ) : (
        <PaymentMethodsTable
          paymentMethods={paymentMethods}
          changeDefaultCallback={changeDefaultPayment}
          deleteMethodCallback={deletePaymentMethodByUuid}
        />
      )}
    </>
  );
};

export default PaymentMethods;
