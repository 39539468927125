import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import here as must be overridden by custom styles
import ReactDOM from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';

import App from './App';
import './i18n/i18n';

datadogRum.init({
  applicationId: 'c72493d1-ba26-4871-87ae-284a73effc7d',
  clientToken: 'pub761045dc2ee217ec1d8aed6c980721f4',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'us5.datadoghq.com', // if changed - allowed new domain in CSP Controller
  service: 'patron-web',
  env: process.env.REACT_APP_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  // defaultPrivacyLevel: 'mask-user-input',
  defaultPrivacyLevel: 'allow', // TODO: Ask for user to give permission for tracking
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
