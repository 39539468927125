/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useFlag } from '@unleash/proxy-client-react';

import { useClaimWristbandForm } from './hooks';
import { createCreditCardSchema } from './hooks/useClaimWristbandForm';

import { Heading, Loader } from 'components';
import WristbandForm from 'components/Form/WristbandForm';
import { CardInfo, ClaimType, useData, useEventsApi, useLedger } from 'data';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { getLinkWithParams, waitFor } from 'navigation/utils';
import { selectEventSelectedDetails, useAppSelector, useAppDispatch } from 'store';
import usePayments from 'data/usePayments';
import { creditCardInfo } from 'components/AddPaymentMethodModal/AddPaymentMethodModal';
import PaymentForm from 'components/Form/PaymentForm';
import useEventUri from 'hooks/useEventUri';
import { Navigation } from 'navigation';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
const ClaimWristband: FC = () => {
  const eventDetails = useAppSelector(selectEventSelectedDetails);
  const location = useLocation();
  const navigate = useNavigate();
  const { securedApi } = useData();
  const { fetchEventWallet } = useLedger();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery();
  const [preparingWallet, setPreparingWallet] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [onAddPaymentMethod, setOnAddPaymentMethod] = useState(false);
  const [enableSavedPaymentMethod, setEnableSavedPaymentMethod] = useState(false);
  const { addPaymentMethod } = usePayments();
  const [claimType, setClaimType] = useState<ClaimType>(); // Default
  const captchaEnabled = useFlag('captchaEnabled');
  const getQueryParam = (param: string) => new URLSearchParams(location.search).get(param);
  const folioId = getQueryParam('folioId') ?? '';
  const extractedId = folioId ? folioId.split(',id:')[1] : null;
  const eventUri = useEventUri(extractedId!);
  const { fetchSelectedEventById } = useEventsApi();
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useClaimWristbandForm(claimType === 'Security Code Only');

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        if (!extractedId) {
          return;
        }
        const fullEventDetails = await securedApi?.EventsApi.fetchEvent(extractedId);
        setEnableSavedPaymentMethod(
          fullEventDetails?.eventDetails?.enableSavedPaymentMethod ?? false,
        );
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    };

    if (extractedId && (!eventDetails || Object.keys(eventDetails).length === 0)) {
      fetchEventDetails();
    }
  }, [extractedId, eventDetails, securedApi?.EventsApi, dispatch, fetchSelectedEventById]);

  const paymentMethods = useForm<creditCardInfo>({
    resolver: yupResolver(createCreditCardSchema(t)),
  });

  useEffect(() => {
    const getClaimConfig = async () => {
      if (extractedId) {
        const resp = await securedApi?.EventsApi.getClaimConfigForEvent(extractedId);
        setClaimType(resp?.claimCredentials ?? 'Security Code Only');
      }
    };

    getClaimConfig();
  }, [extractedId, securedApi?.EventsApi]);

  const onPaymentMethodSubmit: SubmitHandler<creditCardInfo> = async (data) => {
    const dateWithoutSlashes = data.expiryDate.split('/').join('');
    const separatedDate = dateWithoutSlashes.match(/.{1,2}/g);

    if (!separatedDate?.[0] || !separatedDate?.[1]) {
      toast.error('Invalid expiry date format');
      return;
    }

    const newCardInfo: CardInfo = {
      number: data.cardNumber,
      cvv: data.cvv,
      month: separatedDate[0],
      year: '20' + separatedDate[1],
      full_name: data.cardHolderName,
      cardType: undefined,
      default: false,
      zip: undefined,
      country: undefined,
    };

    try {
      setIsLoading(true);
      await addPaymentMethod(newCardInfo, eventUri);

      if (extractedId) {
        setPreparingWallet(true);
        const walletInfo = await waitFor(fetchEventWallet, [folioId], 1000, 10);
        if (walletInfo) {
          const navigatedPage = eventDetails?.enableSavedPaymentMethod
            ? Navigation.PAYMENT_METHODS
            : Navigation.TRANSACTIONS;

          navigate(getLinkWithParams(navigatedPage, { eventId: extractedId! }));
        } else {
          toast.error('Failed to fetch wallet information');
          navigate(`/events?fetchWalletFor=${extractedId}`);
        }
      }
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setPreparingWallet(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const claimWristband = async (
    folioId: string,
    securityCode?: string,
    rfidUid?: string,
  ): Promise<void> => {
    try {
      // Attempt to claim the wristband
      await securedApi?.OrdersApi.claimWristband(
        {
          rfidUid,
          securityCode,
        },
        folioId,
      );

      toast.success('Success! Your wristband/card has been added');

      if (enableSavedPaymentMethod) {
        setOnAddPaymentMethod(true);
        return;
      }

      if (extractedId) {
        setPreparingWallet(true);
        const walletInfo = await waitFor(fetchEventWallet, [folioId], 1000, 10);

        console.debug('Wallet info retrieved:', {
          hasWalletInfo: !!walletInfo,
          extractedId,
        });

        if (walletInfo) {
          const navigatedPage = eventDetails?.enableSavedPaymentMethod
            ? Navigation.PAYMENT_METHODS
            : Navigation.TRANSACTIONS;

          navigate(getLinkWithParams(navigatedPage, { eventId: extractedId! }));
        } else {
          toast.error('Failed to fetch wallet information');
          navigate(`/events?fetchWalletFor=${extractedId}`);
        }
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errors?.[0] || error.message;
      toast.error(errorMessage);
    } finally {
      setPreparingWallet(false);
    }
  };

  const onSubmit = useCallback(
    async ({ wristbandId, securityId }: { wristbandId?: string; securityId: string }) => {
      if (!securityId) {
        toast.error('Security ID is required');
        return;
      }

      if (!claimType) {
        toast.error('Claim type is not configured');
        return;
      }

      try {
        setIsLoading(true);
        if (captchaEnabled) {
          if (!executeRecaptcha) {
            toast.error('reCAPTCHA verification is unavailable');
            return;
          }

          const recaptchaToken = await executeRecaptcha();
          if (!recaptchaToken) {
            toast.error('reCAPTCHA verification failed');
            return;
          }

          const response = await securedApi!.AccountApi.verifyToken({
            token: recaptchaToken,
            siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY! as string,
          });

          if (!response?.data?.success) {
            toast.error('reCAPTCHA verification failed');
            return;
          }
        }

        const rfidUid = wristbandId?.toUpperCase();

        const { existed: wristbandExists } = await securedApi!.OrdersApi.checkIfWristbandExists(
          {
            rfidUid,
            securityCode: securityId,
          },
          folioId,
          claimType,
        );

        if (!wristbandExists) {
          toast.error('Cannot find a wristband');
          return;
        }
        if (claimType === 'Security Code Only') {
          await claimWristband(folioId, securityId);
        } else {
          await claimWristband(folioId, securityId, rfidUid);
        }

        reset();
      } catch (error: any) {
        const errorMessage =
          error?.response?.data?.errors?.[0] || error.message || error?.response?.data;
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [executeRecaptcha, securedApi, reset, claimType, claimWristband, folioId, captchaEnabled],
  );

  if (!claimType) {
    return <Loader />;
  }

  return (
    <div className='overflow-auto h-screen'>
      {isMobile && (
        <>
          <div className='py-3 px-4 flex justify-between items-center text-custom-secondary bg-white'>
            <FontAwesomeIcon icon={faArrowLeft} size='lg' onClick={() => navigate(-1)} />
            <div className='flex-1 text-center'>
              <p className='text-md font-semibold pr-4'>Add Wristband Details</p>
            </div>
          </div>
          <hr className='text-gray-400' />
        </>
      )}
      <div className={`flex flex-1 h-[84px] items-center ${isMobile ? 'bg-white px-4' : ''}`}>
        <Heading
          headingLevel='p'
          className={`${
            isMobile ? 'text-custom-primary' : 'text-custom-secondary'
          } leading-5 font-normal text-base`}
        >
          {onAddPaymentMethod
            ? 'Adding a payment method will allow you to pay directly from your card without needing to top up funds throughout the event.'
            : 'To access event details and begin adding funds, add your wristband details.'}
        </Heading>
      </div>
      {onAddPaymentMethod ? (
        <PaymentForm
          methods={paymentMethods}
          isMobile={isMobile}
          isLoading={isLoading}
          control={control}
          onSubmit={paymentMethods.handleSubmit(onPaymentMethodSubmit)}
          preparingWallet={preparingWallet}
          extractedId={extractedId}
        />
      ) : (
        <WristbandForm
          onSubmit={handleSubmit(onSubmit)}
          isSecurityIdOnly={claimType === 'Security Code Only'}
          isMobile={isMobile}
          isLoading={isLoading}
          isValid={isValid}
          control={control}
          preparingWallet={preparingWallet}
        />
      )}
    </div>
  );
};

export default ClaimWristband;
