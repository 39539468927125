import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resourceToUri } from 'jwt-cert-sdk';

import EventTableItem from './EventTableItem';

import { Heading, Loader } from 'components';
import { useEventsApi } from 'data';
import useLedger from 'data/useLedger';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import {
  selectUiIsUpcomingEventsLoading,
  selectEventsUpcomingEventsArray,
  selectEventsEventSelectorById,
  setSelectedEvent,
  useAppSelector,
} from 'store';
import { useEventServiceIdentity } from 'data/api/EventApi';

const HeaderCell = ({ children }: any) => (
  <div className={'flex-1 text-center font-light text-sm text-custom-secondary py-2.5'}>
    {children}
  </div>
);

const UpcomingEventsTable = ({ searchQuery }: { searchQuery?: string }) => {
  const upcomingEvents = useAppSelector(selectEventsUpcomingEventsArray);
  const isLoading = useSelector(selectUiIsUpcomingEventsLoading);
  const getEvent = useAppSelector(selectEventsEventSelectorById);
  const eventServiceIdentity = useEventServiceIdentity();
  const dispatch = useDispatch();

  const { fetchSelectedEventById } = useEventsApi();
  const { fetchEventWallet } = useLedger();
  const { t } = useTranslation();
  const isMobile = useMediaQuery();

  const getEventWalletAndDetails = useCallback(
    (eventUri: any) => {
      fetchEventWallet(eventUri);
    },
    [fetchEventWallet],
  );

  const onViewEventClick = useCallback(
    (eventId: string) => {
      const eventUri = eventServiceIdentity
        ? resourceToUri({
            resourceId: eventId,
            serviceId: eventServiceIdentity?.sub,
            resourceType: 'cb:event',
          })
        : null;

      dispatch(setSelectedEvent(getEvent(eventId)));
      getEventWalletAndDetails(eventUri!);
      fetchSelectedEventById(eventId);
    },
    [dispatch, getEvent, getEventWalletAndDetails, fetchSelectedEventById, eventServiceIdentity],
  );

  upcomingEvents!.sort((a, b) => b.eventSummary.startDate.localeCompare(a.eventSummary.startDate));

  const searchResults = searchQuery
    ? upcomingEvents!.filter(
        (event) => event.eventSummary.name.en.toLowerCase()?.includes(searchQuery.toLowerCase()),
      )
    : null;

  if (isLoading || upcomingEvents === null) {
    return <Loader />;
  }

  if (upcomingEvents !== null && !isLoading && !upcomingEvents.length) {
    return (
      <div className='px-[20%] flex flex-col items-center justify-center'>
        <Heading
          className='mt-12 mb-6 text-center text-[#6F7FAF]  text-[18px] w-full'
          headingLevel='h4'
        >
          {t('UpcomingEventsTable.emptyText')}
        </Heading>
      </div>
    );
  }

  const eventsToDisplay = searchResults || upcomingEvents;

  return (
    <div className={isMobile ? 'mt-4' : 'rounded-lg border shadow-2xl w-full mt-[1rem]'}>
      {!isMobile ? (
        <div className='flex bg-gray-50 border-b justify-between items-center px-6'>
          <HeaderCell>EVENT</HeaderCell>
          <HeaderCell>START DATE</HeaderCell>
          <HeaderCell></HeaderCell>
        </div>
      ) : null}
      {eventsToDisplay?.map(({ eventSummary, eventDetails, uuid }) => (
        <div key={uuid}>
          <EventTableItem
            key={uuid}
            uuid={uuid}
            eventSummary={eventSummary}
            eventDetails={eventDetails}
            onViewEventClick={onViewEventClick}
          />
        </div>
      ))}
    </div>
  );
};

export default UpcomingEventsTable;
