import React, { FC } from 'react';
import classNames from 'classnames';

import { Spinner } from 'components';

type SwitchProps = {
  isChecked?: boolean;
  name?: string;
  isLoading?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const Switch: FC<SwitchProps> = ({ onChange, isChecked = false, name = '', isLoading = false }) => {
  return (
    <label className='relative inline-flex items-center cursor-pointer'>
      <input
        type='checkbox'
        value=''
        className='sr-only peer'
        name={name}
        checked={isChecked}
        title={name}
        onChange={onChange}
      />
      {/* Track */}
      <div
        className={classNames(
          'w-14 h-8 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300',
          'dark:peer-focus:ring-blue-800 rounded-full dark:bg-gray-700 peer-checked:bg-emerald-500',
        )}
      />
      {/* Thumb */}
      {isLoading ? (
        <div
          className={classNames(
            'peer-checked:translate-x-6 absolute top-[4px] left-[2px] bg-white border rounded-full',
            'h-6 w-6 flex items-center justify-center',
          )}
        >
          <Spinner color='gray' size='[10px]' />
        </div>
      ) : (
        <div
          className={classNames(
            'peer-checked:translate-x-6 absolute top-[4px] left-[2px] bg-white border rounded-full',
            'h-6 w-6 transition-all border-gray-300 peer-checked:bg-emerald-600 peer-checked:border-blue-600',
          )}
        />
      )}
    </label>
  );
};

export default Switch;
