import React, { FC } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import { MenuLinkType } from 'components';
import { selectEventSelectedNameEn } from 'store';

type MenuProps = {
  links: MenuLinkType[];
  parentSectionPath?: string;
  variant?: 'primary' | 'secondary';
};

export const menuClassNames = {
  primary: {
    li: ['text-[#f9f9f9]'],
    navLink: [],
    active: ['bg-[rgba(255,255,255,0.2)]'],
    hover: ['hover:bg-[rgba(255,255,255,0.2)]'],
    default: [''],
  },
  secondary: {
    li: ['text-custom-primary'],
    navLink: [],
    active: ['bg-[#E8EFEF]', 'text-[#00303B]'],
    hover: ['hover:bg-[#E8EFEF]', 'hover:text-[#00303B]'],
    default: ['text-[#00303B]'],
  },
};

const isActiveTab = (pathname: string, to: string): boolean => pathname.includes(to);

const Menu: FC<MenuProps> = ({ links, parentSectionPath, variant = 'primary' }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const eventName = useSelector(selectEventSelectedNameEn);

  return (
    <ul>
      {parentSectionPath ? (
        <nav className='flex items-center pl-4 pb-1 text-[#079286] text-md'>
          <button
            className='pr-4'
            onClick={() => {
              navigate(parentSectionPath);
            }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ width: 20, height: 20 }}
              className='text-[#079286] pt-1'
            />
          </button>
          <span>{eventName}</span>
        </nav>
      ) : null}
      {links
        .filter(({ isHidden }) => !isHidden)
        .map(({ onClick, to, name, icon, target }) => {
          const isActive =
            (to === '/settings' && pathname === '/manage-profile-info') ||
            isActiveTab(pathname, to);

          return (
            <li
              key={name}
              className={`${menuClassNames[variant].li} md:px-[10px] py-[4px]`}
              onClick={onClick}
            >
              <NavLink
                className={classNames(
                  'flex md:pl-[10px] py-[8px] cursor-pointer items-center p-2 text-base font-normal rounded-lg hover:text-inherit',
                  isActive && 'hover:bg-[rgba(255,255,255,0.2)]',
                  menuClassNames[variant][isActive ? 'active' : 'default'],
                  menuClassNames[variant].hover,
                )}
                to={to}
                target={target}
                end
              >
                <span className='w-[18px] h-[18px]'>{icon}</span>
                <span className='flex-1 text-base align-center ml-[12pt]'>{name}</span>
              </NavLink>
            </li>
          );
        })}
    </ul>
  );
};

export default Menu;
