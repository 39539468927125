import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronLeft, faRedo } from '@fortawesome/free-solid-svg-icons';
import { User } from 'firebase/auth';
import { FormGroup, Row } from 'reactstrap';

import { Button } from '../components';

import { Navigation } from 'navigation';
import envelope from 'assets/images/envelope.png';
import { useAuthValue, useProvideAuth } from 'hooks/Firebase';
import useMediaQuery, { isSmallMobileScreen } from 'hooks/mediaQuery/useMediaQuery';

type SignUpSuccessProps = {
  email: string;
  seconds: number;
  isLoading: boolean;
  onResend: any;
};

const SignUpSuccess: FC<SignUpSuccessProps> = ({ email, seconds, isLoading, onResend }) => {
  const { refreshUser } = useAuthValue();
  const navigate = useNavigate();
  const { logOut } = useProvideAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery();

  useEffect(() => {
    navigate({ search: '?hideHeader=true' });
  }, [navigate]);

  const onCancel = async () => {
    await logOut();
    window.location.href = Navigation.LOGIN;
  };

  const reload = useCallback(() => {
    refreshUser().then((user: User) => {
      if (user && user.emailVerified) {
        navigate(Navigation.EVENTS);
      }
    });
  }, [navigate, refreshUser]);

  useEffect(() => {
    const reloadFn: ReturnType<typeof setTimeout> = setTimeout(() => reload(), 10 * 1000);
    return () => {
      clearTimeout(reloadFn);
    };
  }, [reload]);
  return isMobile ? (
    <>
      <div className='flex flex-row justify-between text-custom-secondary font-light font-LRegular text- lg py-4'>
        <button onClick={onCancel}>
          <FontAwesomeIcon icon={faArrowLeft} size='lg' />
        </button>
        <span>{t('AccountCreation.header')}</span>
        <span>1 of 3</span>
      </div>

      <div className='flex flex-col items-center justify-center h-screen mt-[-150px]'>
        <img className='d-block mx-auto mb-4' src={envelope} alt='sent' width={150} />
        <h4 className='text-center mb-2'>{t('SignUpSuccess.text.title')}</h4>
        <p className='text-center text-[#5e6e82] font-light mb-3'>
          {t('SignUpSuccess.text.description', { email })}
        </p>
      </div>

      <div className='fixed bottom-0 left-0 right-0 p-3'>
        <FormGroup>
          <Button
            block
            className='mt-3 bg-intelli-mainColor py-2'
            isDisabled={isLoading || seconds > 0}
            isLoading={isLoading}
            onClick={onResend}
          >
            {seconds
              ? t('SignUpSuccess.button.resendEmailConfirmationWaiting', { seconds })
              : t('SignUpSuccess.button.resendEmailConfirmation')}
          </Button>
        </FormGroup>
        <div
          className={`form-support flex items-center justify-center text-[1.2rem] ${
            isSmallMobileScreen() ? 'mb-2' : 'mb-4'
          } text-center`}
        >
          <a
            href={Navigation.SUPPORT}
            target='_blank'
            rel='noreferrer'
            className='fs--1 font-[Open Sans] !text-[#748194] leading-6 font-extralight'
          >
            {t('AuthLayout.troubleLoggingIn')}
            <br />
            {t('AuthLayout.contactSupport')}
            <span className='d-inline-block ml-1'>&rarr;</span>
          </a>
        </div>
      </div>
    </>
  ) : (
    <>
      <img className='d-block mx-auto mb-4' src={envelope} alt='sent' width={70} />
      <h4 className='text-center mb-2'>{t('SignUpSuccess.text.title')}</h4>
      <p className='text-center text-[#5e6e82] font-light mb-3'>
        {t('SignUpSuccess.text.description', { email })}
      </p>
      <Row className='justify-center'>
        <Button
          size='sm'
          color='primary'
          className='m-center mt-3'
          isDisabled={isLoading || seconds > 0}
          isLoading={isLoading}
          isLoadingContent={t('SignUpSuccess.button.loading')}
          onClick={onResend}
        >
          <FontAwesomeIcon icon={faRedo} transform='shrink-4' className='mr-1' />
          {seconds
            ? t('SignUpSuccess.button.resendEmailConfirmationWaiting', { seconds })
            : t('SignUpSuccess.button.resendEmailConfirmation')}
        </Button>
      </Row>
      <Row className='justify-center'>
        <Button size='sm' color='primary' className='m-center mt-3' onClick={onCancel}>
          <FontAwesomeIcon icon={faChevronLeft} transform='shrink-4' className='mr-1' />
          {t('SignUpSuccess.button.backToLogin')}
        </Button>
      </Row>
    </>
  );
};

export default SignUpSuccess;
