import React from 'react';
import { useNavigate } from 'react-router-dom';

import paymentMethod from 'assets/images/paymentMethod.png';
import { Button } from 'components';
import AddCreditCardInputs from 'components/AddCard/components/AddCardInputs/AddCreditCardInputs';

interface PaymentFormProps {
  methods: any;
  isMobile: boolean;
  isLoading: boolean;
  control: any;
  onSubmit: any;
  preparingWallet: boolean;
  extractedId: string | null;
}

function PaymentForm({
  methods,
  isMobile,
  isLoading,
  onSubmit,
  preparingWallet,
  extractedId,
}: PaymentFormProps) {
  const navigate = useNavigate();
  return (
    <div className='md:flex md:justify-between rounded shadow-md'>
      <div className='md:w-2/5 md:pr-8 bg-gray-50 rounded '>
        <div className='hidden md:block p-[4px] max-w-[320px]'>
          <div className='overflow-hidden'>
            <img src={paymentMethod} alt='intellitix-wristband-reference' className='w-full' />
          </div>
        </div>
        <p className='text-[#6F7FAF] pt-6 hidden md:block'></p>
      </div>
      <div className={isMobile ? '' : 'md:w-3/5 bg-white p-4 rounded'}>
        <div className={`${isMobile && 'px-4 py-2 bg-white'}`}>
          <div className={`${isMobile ? 'hidden' : ''}`}>
            <p className={`text-[32px] text-[#232E59] font-bold pb-6 text-xl md:text-3xl`}>
              Add Payment Method
            </p>
            <p className='text-[#6F7FAF] pb-6'>
              Fill out the following information to link a payment method to your wristband.
            </p>
          </div>
          <form onSubmit={onSubmit}>
            <AddCreditCardInputs methods={methods} />
            {isMobile && (
              <div className=' md:hidden p-[4px] max-w-[320px]'>
                <div className='overflow-hidden'>
                  <img
                    src={paymentMethod}
                    alt='intellitix-wristband-reference'
                    className='w-full'
                  />
                </div>
              </div>
            )}
            <div
              className={`w-full items-center mt-[1rem] ${
                isMobile ? 'hidden' : 'flex flex-row gap-4 justify-end flex-end'
              }`}
            >
              <Button
                isLoading={isLoading}
                loadingText={preparingWallet ? 'Preparing Account' : ''}
                type='button'
                onClick={() => navigate(`/events/${extractedId}/transactions`)}
                className='btn btn-secondary w-full md:w-[114px] h-[38px] font-medium text-custom-primary'
              >
                Skip
              </Button>
              <Button
                isLoading={isLoading}
                loadingText={preparingWallet ? 'Preparing Account' : ''}
                type='submit'
                className='btn btn-primary w-full md:w-[114px] h-[38px] disabled:opacity-25'
              >
                Finish
              </Button>
            </div>

            <div className='md:hidden fixed bottom-0 left-0 right-0 p-4 bg-white shadow-md rounded-xl flex justify-between items-center w-full'>
              <Button
                isLoading={isLoading}
                loadingText={preparingWallet ? 'Preparing Account' : ''}
                type='button'
                onClick={() => navigate(`/events/${extractedId}/transactions`)}
                className='btn btn-secondary rounded-lg w-[38%] h-[48px] font-medium text-custom-primary'
              >
                Skip
              </Button>
              <Button
                isLoading={isLoading}
                loadingText={preparingWallet ? 'Preparing Account' : ''}
                type='submit'
                className='btn btn-primary rounded-lg w-[58%]  h-[48px] disabled:opacity-25'
              >
                Finish
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PaymentForm;
