import React from 'react';
import { twMerge } from 'tailwind-merge';

function Section({
  label,
  value,
  actionLabel,
  onActionClick,
  icon,
  showAction = true,
  wrapperClassName,
}: {
  label: string;
  value: React.ReactNode;
  actionLabel?: string;
  onActionClick?: () => void;
  icon?: React.ReactNode;
  showAction?: boolean;
  wrapperClassName?: string;
}) {
  return (
    <div
      className={twMerge(
        'w-full bg-gray-100 rounded p-2 flex justify-between px-4 items-center',
        wrapperClassName,
      )}
    >
      <div>
        <div className='text-custom-secondary text-sm pb-1'>{label}</div>
        <div className='flex gap-4'>
          <div className='text-custom-primary font-medium'>{value}</div>
          {icon && <div className='text-gray-400'>{icon}</div>}
        </div>
      </div>
      {showAction && (
        <div className='text-[#079286] underline cursor-pointer' onClick={onActionClick}>
          {actionLabel}
        </div>
      )}
    </div>
  );
}
export default Section;
