import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

const getId = (_state: RootState, id: string): string => id;

export const selectOrdersData = (state: RootState) => state.ordersData;

export const selectOrdersStatus = createSelector(
  [selectOrdersData],
  (ordersData) => ordersData.status,
);

export const selectOrdersIsLoading = createSelector(
  [selectOrdersStatus],
  (ordersStatus) => ordersStatus === 'loading',
);

export const selectOrdersTickets = createSelector(
  [selectOrdersData],
  (ordersData) => ordersData.tickets || {},
);

export const selectOrdersUserTickets = createSelector(
  [selectOrdersTickets],
  (tickets) => tickets.userTickets || {},
);

export const selectOrdersUserTicketsArr = createSelector([selectOrdersUserTickets], (userTickets) =>
  Object.values(userTickets),
);

export const selectOrdersTransferredTickets = createSelector(
  [selectOrdersTickets],
  (tickets) => tickets.transferredTickets || {},
);

export const selectOrdersTransferredTicketsArr = createSelector(
  [selectOrdersTransferredTickets],
  (transferredTickets) => Object.values(transferredTickets),
);

export const selectOrdersUserTicketsAmount = createSelector(
  [selectOrdersUserTicketsArr],
  (userTickets) => userTickets.length,
);

export const selectOrdersUserTicketByTicketId = createSelector(
  [selectOrdersUserTickets, getId],
  (userTickets, id) => userTickets[id] || {},
);

export const selectUserTicketFolioIdByTicketId = createSelector(
  [selectOrdersUserTicketByTicketId],
  (userTicket) => userTicket.folioId,
);

export const selectUserTicketContactInfoByProductId = createSelector(
  [selectOrdersUserTicketByTicketId],
  (userTicket) => userTicket.contact_info || {},
);

export const selectTopupTokens = createSelector(
  [selectOrdersData],
  (ordersData) => ordersData.topupTokens,
);

export const selectTokenIdByTopUpFolioUuid = createSelector(
  [selectTopupTokens, (_state, folioUuid) => folioUuid],
  (topupTokens, folioUuid) => topupTokens[folioUuid],
);

export const selectIsWristbandFrozen = createSelector(
  [selectOrdersUserTicketByTicketId],
  (userTicket) => userTicket?.isFrozen ?? false,
);

export const selectOrdersAccountTickets = createSelector(
  [selectOrdersData],
  (ordersData) => ordersData.accountTickets || {},
);

export const selectAccountTicketsValid = createSelector(
  [selectOrdersAccountTickets],
  (accountTickets) => accountTickets.validTicket || [],
);

export const selectCurrentWallet = (state: RootState) => state.ordersData.currentWallet;
