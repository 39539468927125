import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { TextField } from 'components';

type ControlledTextFieldProps = {
  control: any;
  fieldName: string;
  label?: string;
  placeholder?: string;
  type?: string;
  maxLength?: number;
  labelClassName?: string;
  value?: any;
  className?: string;
  dataTestId?: string;
  upperCase?: boolean;
};

const ControlledTextField: FC<ControlledTextFieldProps> = ({
  control,
  fieldName,
  label,
  placeholder,
  labelClassName,
  className,
  dataTestId,
  upperCase,
  ...restProps
}) => (
  <Controller
    control={control}
    name={fieldName}
    render={({ field, fieldState }) => (
      <TextField
        data-testid={dataTestId}
        className={className}
        label={label}
        labelClassName={labelClassName}
        placeholder={placeholder}
        name={field.name}
        onChange={
          upperCase
            ? (e) => {
                const upperCaseValue = e.target.value.toUpperCase();
                field.onChange(upperCaseValue);
              }
            : field.onChange
        }
        onBlur={field.onBlur}
        value={field.value}
        isError={fieldState.invalid}
        errorMsg={fieldState.error?.message}
        {...restProps}
      />
    )}
  />
);

export default ControlledTextField;
