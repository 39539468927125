import axios from 'axios';

import { Config } from '../Config';

import { ClientConfig, SecuredApi } from './Api';

import { PaymentMethod, CardInfo } from 'data/model';

interface PaymentsApi {
  getPaymentMethods: (eventUri: any) => Promise<PaymentMethod[]>;
  createPaymentMethod: (cardInfo: CardInfo) => Promise<PaymentMethod>;
  deletePaymentMethod: (paymentMethodId: string, eventUri: any) => Promise<PaymentMethod[]>;
  setDefaultStatus: (paymentMethodId: string, eventUri: any) => Promise<PaymentMethod[]>;
  getPaymentMethodsByEvent: (eventUri: any) => Promise<PaymentMethod[]>;
  createPaymentMethodsByEvent: (cardInfo: CardInfo, eventUri: any) => Promise<PaymentMethod>;
  sendPaymentMethod: (paymentId: string, paymentMethodId: string) => Promise<any>;
  sendNewPaymentMethod: (paymentId: string, spreedlyToken: string) => Promise<any>;
  pollPayment: (paymentId: string) => Promise<any>;
  createSpreedlyCreditCard: (data: string) => Promise<any>;
}

export class PaymentsApiImpl extends SecuredApi implements PaymentsApi {
  authtokenTTL = 180;
  rfidSearch = '';
  clientConfig = (): ClientConfig => {
    return {
      baseURL: Config.PAYMENTS,
      timeout: 15000,
    };
  };

  getPaymentMethods = async (): Promise<PaymentMethod[]> => {
    const response = await this.client.get('/user/paymentMethods');
    return response.data.entries;
  };

  createPaymentMethod = async (cardInfo: CardInfo) => {
    const cardInfoPayload = cardInfo;
    const config = {
      method: 'post',
      url: Config.SPREEDLY_PAYMENTS,
      headers: {
        'Content-Type': 'application/json',
      },
      data: cardInfoPayload,
    };

    const spreedlyResponseObject = await axios(config);
    const data = {
      spreedlyPaymentMethodToken: spreedlyResponseObject.data.transaction.payment_method.token,
      nickname: spreedlyResponseObject.data.transaction.token,
      default: cardInfo.default,
      meta: {
        cardType: spreedlyResponseObject.data.transaction.payment_method.card_type,
        number: spreedlyResponseObject.data.transaction.payment_method.last_four_digits,
        firstName: spreedlyResponseObject.data.transaction.payment_method.first_name,
        lastName: spreedlyResponseObject.data.transaction.payment_method.last_name,
        zip: spreedlyResponseObject.data.transaction.payment_method.zip,
        country: spreedlyResponseObject.data.transaction.payment_method.country,
      },
    };

    const response = await this.client.post(`user/paymentMethods`, data);
    return response.data;
  };

  deletePaymentMethod = async (
    paymentMethodId: string,
    eventUri: any,
  ): Promise<PaymentMethod[]> => {
    const response = await this.client.delete(
      `event/${eventUri}/paymentMethods/${paymentMethodId}`,
    );
    return response.data;
  };

  setDefaultStatus = async (paymentMethodId: string, eventUri: any): Promise<PaymentMethod[]> => {
    const response = await this.client.put(
      `event/${eventUri}/paymentMethods/${paymentMethodId}/default`,
    );
    return response.data;
  };

  // Send payment using paymentMethodId from stored payment method
  sendPaymentMethod = async (paymentId: string, paymentMethodId: string): Promise<any> => {
    const body = {
      paymentMethodId,
    };

    const response = await this.client.put(`user/payments/${paymentId}/paymentMethod`, body);
    return response.data;
  };

  // Send payment using spreedlyToken from new payment method
  sendNewPaymentMethod = async (paymentId: string, spreedlyToken: string): Promise<any> => {
    const body = {
      remember: false,
      paymentMethod: {
        spreedlyPaymentMethodToken: spreedlyToken,
      },
    };
    const response = await this.client.post(`user/payments/${paymentId}/paymentMethod`, body);
    return response.data;
  };

  pollPayment = async (paymentId: string): Promise<any> => {
    const response = await this.client.get(`user/payments/${paymentId}`);
    return response.data;
  };

  createSpreedlyCreditCard = async (data: string): Promise<any> => {
    const config = {
      method: 'post',
      url: Config.SPREEDLY_PAYMENTS,
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    };
    const response = await axios(config);
    return response.data;
  };
  getPaymentMethodsByEvent = async (eventUri: any): Promise<PaymentMethod[]> => {
    const response = await this.client.get(`event/${eventUri}/paymentMethods`);
    return response.data.entries;
  };

  createPaymentMethodsByEvent = async (
    cardInfo: CardInfo,
    eventUri: any,
  ): Promise<PaymentMethod> => {
    const cardInfoPayload = cardInfo;
    const config = {
      method: 'post',
      url: Config.SPREEDLY_PAYMENTS,
      headers: {
        'Content-Type': 'application/json',
      },
      data: cardInfoPayload,
    };

    const spreedlyResponseObject = await axios(config);
    const data = {
      spreedlyPaymentMethodToken: spreedlyResponseObject.data.transaction.payment_method.token,
      nickname: spreedlyResponseObject.data.transaction.token,
      default: cardInfo.default,
      meta: {
        cardType: spreedlyResponseObject.data.transaction.payment_method.card_type,
        number: spreedlyResponseObject.data.transaction.payment_method.last_four_digits,
        firstName: spreedlyResponseObject.data.transaction.payment_method.first_name,
        lastName: spreedlyResponseObject.data.transaction.payment_method.last_name,
        zip: spreedlyResponseObject.data.transaction.payment_method.zip,
        country: spreedlyResponseObject.data.transaction.payment_method.country,
      },
    };

    const response = await this.client.post(`event/${eventUri}/paymentMethods`, data);
    return response.data;
  };
}

export default PaymentsApi;
