import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'reactstrap';

import { PaymentMethod } from 'data';
import { replaceSpreedlyHideString } from 'helpers/creditCardUtils';

interface PaymentMethodSelectorProps {
  paymentMethods: PaymentMethod[];
  setSelectedPaymentMethodId: (id: string) => void;
  selectedPaymentMethodId: string;
}

const PaymentMethodSelector: React.FC<PaymentMethodSelectorProps> = ({
  paymentMethods,
  setSelectedPaymentMethodId,
  selectedPaymentMethodId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTempPaymentMethodId, setSelectedTempPaymentMethodId] =
    useState<string>(selectedPaymentMethodId);

  useEffect(() => {
    setSelectedTempPaymentMethodId(selectedPaymentMethodId);
  }, [selectedPaymentMethodId]);

  const selectedMethod = paymentMethods.find(({ uuid }) => uuid === selectedPaymentMethodId);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // Handles the temporary payment selection logic without closing the modal
  const handleTempPaymentSelect = (methodId: string) => {
    setSelectedTempPaymentMethodId(methodId);
  };

  // Apply the changes and close the modal
  const handleUpdate = () => {
    setSelectedPaymentMethodId(selectedTempPaymentMethodId);
    toggleModal();
  };

  return (
    <div className='pt-3'>
      {selectedMethod && (
        <div className='flex items-center justify-between px-3 py-2 bg-white rounded-md border border-gray-200 shadow-sm hover:shadow-md transition-shadow duration-200'>
          <div className='flex flex-col'>
            <div className='flex items-center space-x-2'>
              <span className='text-lg font-bold text-[#232E59]'>Visa</span>
              <span className='text-lg font-semibold text-custom-secondary mt-2'>
                {replaceSpreedlyHideString(selectedMethod.info.spreedlyPaymentMethod.number)}
              </span>
            </div>
            <span className='text-sm text-gray-500'>
              {String(selectedMethod.info.spreedlyPaymentMethod.month).padStart(2, '0')}/
              {selectedMethod.info.spreedlyPaymentMethod.year}
            </span>
          </div>
          <button
            onClick={toggleModal}
            className='text-[#079286] font-medium underline hover:text-[#079286] transition-colors duration-200'
          >
            Change
          </button>
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        className='fixed bottom-0 left-0 right-0 m-0 w-full h-auto'
        modalClassName='border-0 overflow-hidden'
      >
        <div className='space-y-4 px-4 py-3 rounded-xl'>
          <div className='text-lg text-custom-primary font-medium py-2'>Select Payment Method</div>
          {paymentMethods.length > 0 &&
            paymentMethods.map((card: PaymentMethod) => (
              <React.Fragment key={card.uuid}>
                <div
                  onClick={() => handleTempPaymentSelect(card.uuid)}
                  className={`flex items-center justify-between px-3 py-2 rounded-md border cursor-pointer transition-shadow duration-200 ${
                    card.uuid === selectedTempPaymentMethodId
                      ? 'bg-intelli-hightlight border-black shadow-md'
                      : 'bg-white border-gray-200 hover:shadow-md'
                  }`}
                >
                  <div className='flex flex-col'>
                    <div className='flex items-center space-x-2'>
                      <span className='text-lg font-bold text-[#232E59]'>VISA</span>
                      <span className='text-lg font-semibold text-custom-secondary mt-2'>
                        {replaceSpreedlyHideString(card.info.spreedlyPaymentMethod.number)}
                      </span>
                    </div>
                    <span className='text-sm text-gray-500'>
                      {String(card.info.spreedlyPaymentMethod.month).padStart(2, '0')}/
                      {card.info.spreedlyPaymentMethod.year}
                    </span>
                  </div>
                  {card.default && (
                    <div className='bg-gray-200 text-xs rounded-xl px-2 py-1'>Default</div>
                  )}
                </div>
              </React.Fragment>
            ))}
        </div>
        <div className='flex justify-between px-4 py-3'>
          <Button color='primary' onClick={handleUpdate} className='w-full'>
            Update
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentMethodSelector;
