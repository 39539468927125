import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { EventActions } from 'store/events';
import { LedgerActions } from 'store/ledger';
import { OrdersActions } from 'store/orders';
import { UserActions } from 'store/user';
import { PaymentsActions } from 'store/payments';

type ComposedActionTypes =
  | EventActions
  | UserActions
  | OrdersActions
  | LedgerActions
  | PaymentsActions;

const selectUiData = (state: RootState) => state.ui;

const getIsLoadingByActionType =
  (actionType: ComposedActionTypes) =>
  (loadingActions: string[]): boolean =>
    !!loadingActions.find((loadingAction) => loadingAction === actionType);

/**
 * LOADERS
 */
export const selectUiLoadingActions = createSelector([selectUiData], (ui) => ui.loadingActions);

export const selectUiIsSelectedEventLoading = createSelector(
  [selectUiLoadingActions],
  getIsLoadingByActionType(EventActions.FETCH_SELECTED_EVENT_BY_ID),
);

export const selectUiIsUpcomingEventsLoading = createSelector(
  [selectUiLoadingActions],
  getIsLoadingByActionType(EventActions.FETCH_UPCOMING_EVENTS),
);

export const selectUiIsTransactionHistoryLoading = createSelector(
  [selectUiLoadingActions],
  getIsLoadingByActionType(EventActions.FETCH_TRANSACTION_HISTORY),
);

export const selectUiIsUserDataLoading = createSelector(
  [selectUiLoadingActions],
  getIsLoadingByActionType(UserActions.FETCH_USER_DATA),
);

export const selectUiIsUserDetailedInfoLoading = createSelector(
  [selectUiLoadingActions],
  getIsLoadingByActionType(UserActions.FETCH_USER_DETAILED_INFO),
);

export const selectUiIsUpdateUserLoading = createSelector(
  [selectUiLoadingActions],
  getIsLoadingByActionType(UserActions.UPDATE_USER),
);

export const selectUiIsFetchProductsLoading = createSelector(
  [selectUiLoadingActions],
  getIsLoadingByActionType(OrdersActions.FETCH_PRODUCTS),
);

export const selectUiIsCreateTopUpOrderLoading = createSelector(
  [selectUiLoadingActions],
  getIsLoadingByActionType(OrdersActions.CREATE_TOP_UP_ORDER),
);

export const selectUiIsFetchWalletLoading = createSelector(
  [selectUiLoadingActions],
  getIsLoadingByActionType(LedgerActions.FETCH_WALLET),
);

export const selectUiIsFetchWalletDetailsLoading = createSelector(
  [selectUiLoadingActions],
  getIsLoadingByActionType(LedgerActions.FETCH_WALLET_DETAILS),
);

export const selectUiIsSettingDefaultPaymentMethod = createSelector(
  [selectUiLoadingActions],
  getIsLoadingByActionType(PaymentsActions.SET_DEFAULT_PAYMENT_METHOD),
);

export const selectUiIsFetchingPaymentMethods = createSelector(
  [selectUiLoadingActions],
  getIsLoadingByActionType(PaymentsActions.FETCH_PAYMENT_METHODS),
);

/**
 * COMBINED LOADERS
 */

/**
 * @todo
 * Add loading states for wallet and wallet details
 * (selectUiIsFetchWalletLoading, selectUiIsFetchWalletDetailsLoading)
 */
export const selectUiIsEventLayoutLoading = createSelector(
  [selectUiIsUserDataLoading, selectUiIsSelectedEventLoading],
  (userDataLoader, selectedEventLoader) => userDataLoader || selectedEventLoader,
);

/**
 * REJECTED
 */
export const selectUiRejectedAction = createSelector([selectUiData], (ui) => ui.rejectedActions);

export const selectUiIsFetchUserDataRejected = createSelector(
  [selectUiRejectedAction],
  (rejectedActions) =>
    Object.prototype.hasOwnProperty.call(rejectedActions, UserActions.FETCH_USER_DATA),
);
