import { resourceToUri } from 'jwt-cert-sdk';
import { useParams } from 'react-router-dom';

import { useEventServiceIdentity } from 'data/api/EventApi';

const useEventUri = (uuid?: string) => {
  const { eventId } = useParams();
  const eventServiceIdentity = useEventServiceIdentity();

  if (!uuid && !eventId) {
    console.warn('Failed to generate eventUri, no eventId was provided');
    return '__ERROR__EVENT_URI_NOT_GENERATED!__';
  }

  const eventUri = eventServiceIdentity
    ? resourceToUri({
        resourceId: uuid || eventId!,
        serviceId: eventServiceIdentity?.sub,
        resourceType: 'cb:event',
      })
    : null;

  return eventUri;
};

export default useEventUri;
