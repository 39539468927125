import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { UserIcon } from 'components/icons';
import { useAuthValue, useProvideAuth } from 'hooks/Firebase';
import { Navigation } from 'navigation';
import { selectUserDetailedInfoLoading, selectUserHasContactInfo, useAppSelector } from 'store';

export const useSecondaryMenuLinks = (): any[] => {
  const hasContactInfo = useAppSelector(selectUserHasContactInfo);
  const isContactInfoLoading = useAppSelector(selectUserDetailedInfoLoading);
  const { logOut } = useProvideAuth();
  const { clearAuthProvider } = useAuthValue();

  const isLinkDisabled = !hasContactInfo || isContactInfoLoading;

  const links = useMemo(
    () => [
      {
        name: 'Support',
        to: Navigation.SUPPORT,
        target: '_blank',
        icon: <UserIcon />,
        isHidden: isLinkDisabled,
      },
      {
        name: 'Log Out',
        to: Navigation.SIGN_OUT,
        icon: (
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{ width: 20, height: 20 }}
            className='text-intelli-primary'
          />
        ),
        onClick: () => {
          if (clearAuthProvider) clearAuthProvider();
          logOut();
        },
      },
    ],
    [clearAuthProvider, isLinkDisabled, logOut],
  );

  return links;
};
