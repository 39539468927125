import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useMenuLinks } from 'hooks';

const MobileNavigationTabs: React.FC = () => {
  const { pathname } = useLocation();
  const { links } = useMenuLinks('event');

  return (
    <div className='w-full p-3 flex overflow-x-auto whitespace-nowrap space-x-3'>
      {links.map((tab, index) => {
        const isActive = pathname.includes(tab.to);
        return (
          <Link
            key={index}
            to={tab.to}
            className={`px-2 py-2 rounded-lg text-sm text-custom-secondary ${
              isActive ? 'bg-intelli-hightlight' : 'bg-gray-100 text-gray-700'
            } transition-colors duration-300 `}
          >
            {tab.name}
          </Link>
        );
      })}
    </div>
  );
};

export default MobileNavigationTabs;
