import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

/* eslint-disable react/prop-types */
import PaymentMethodSelector from './MobilePaymentMethodSelector';

import { PaymentMethod, useEventsApi, useOrders } from 'data';
import useLedger from 'data/useLedger';
import usePayments from 'data/usePayments';
import {
  AppDispatch,
  setSelectedEvent,
  selectEventsEventSelectorById,
  useAppSelector,
  selectEventCurrency,
  selectUiIsFetchProductsLoading,
  selectUiIsCreateTopUpOrderLoading,
} from 'store';
import {
  buildCurrencyBalance,
  getCurrencySymbolForCurrencyCode,
  getMinimumTopUpAmount,
} from 'helpers/currency';
import { Box, CurrencyAmountConverter, PayNowModal } from 'components';
import { getCreditCardIconByType, replaceSpreedlyHideString } from 'helpers/creditCardUtils';
import PaymentMethodRowDisplay from 'components/layout/PaymentMethodsTable/PaymentMethodRowDisplay';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

const ManageFunds: FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const isMobile = useMediaQuery();
  const getEvent = useAppSelector(selectEventsEventSelectorById);
  const [payEnabled, setPayEnabled] = useState(true);
  const isFetchProductLoading = useAppSelector(selectUiIsFetchProductsLoading);
  const isCreateTopUpOrderLoading = useAppSelector(selectUiIsCreateTopUpOrderLoading);
  const { createTopUpOrderAndPayWithExistingCreditCard, createTopUpOrderAndPayWithNewCreditCard } =
    useOrders();
  const { fetchSelectedEventById } = useEventsApi();
  const { getAllPaymentMethods, paymentMethods, changeDefaultPayment } = usePayments();
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string | null>(null);
  const { eventId } = useParams();
  const [topUpAmount, setTopUpMount] = useState(0);
  const currency = useSelector(selectEventCurrency);
  const minimumAmount = getMinimumTopUpAmount(currency);
  const currencySymbol = getCurrencySymbolForCurrencyCode(currency);
  const { currentWalletBalance, fetchEventWallet } = useLedger();
  const balance = buildCurrencyBalance(currency, currencySymbol, currentWalletBalance);
  const balanceAfterTopUp = buildCurrencyBalance(
    currency,
    currencySymbol,
    currentWalletBalance + topUpAmount,
  );
  const [isPayModalOpen, setIsPayModalOpen] = useState(false);

  const paymentIsLoading = isFetchProductLoading || isCreateTopUpOrderLoading;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllPaymentMethods();
  }, [getAllPaymentMethods]);

  useEffect(() => {
    if (eventId) {
      dispatch(setSelectedEvent(getEvent(eventId)));
      fetchSelectedEventById(eventId);
      fetchEventWallet(eventId);
    }
  }, [dispatch, eventId, fetchEventWallet, fetchSelectedEventById, getEvent]);

  const handleAmountChange = (e: number) => {
    setTopUpMount(e);
  };

  useEffect(() => {
    const defaultMethod = paymentMethods.find((method) => method.default);
    if (defaultMethod && !selectedPaymentMethodId) {
      setSelectedPaymentMethodId(defaultMethod.uuid);
    }
  }, [paymentMethods, selectedPaymentMethodId]);

  const handlePayButtonOnClick = useCallback(async () => {
    if (!selectedPaymentMethodId) {
      console.log('No payment method selected');
      return;
    }
    setPayEnabled(false);

    try {
      await createTopUpOrderAndPayWithExistingCreditCard(
        selectedPaymentMethodId,
        topUpAmount * 100,
      );
      toast.success('Top-up successful');
    } catch {
      toast.error('Top-up failed');
    } finally {
      setPayEnabled(true);
      setIsPayModalOpen(false);
    }
  }, [createTopUpOrderAndPayWithExistingCreditCard, selectedPaymentMethodId, topUpAmount]);

  // Create a new order and pay with a new credit card
  const instantPayWithCardInfo = useCallback(
    async (cardInfo: any, callback?: () => void) => {
      setPayEnabled(false);
      try {
        await createTopUpOrderAndPayWithNewCreditCard(cardInfo, topUpAmount * 100);
      } catch {
        toast.error('Top-up failed');
      } finally {
        setPayEnabled(true);
        callback && callback();
      }
    },
    [createTopUpOrderAndPayWithNewCreditCard, topUpAmount],
  );

  const renderDesktopView = () => (
    <Box>
      <div className='flex gap-4'>
        <div className='w-2/5'>
          <div className='text-custom-primary text-2xl font-bold pl-6 pb-2'>Manage Funds</div>
          <div className='text-[#6f7faf] text-base pl-6 pb-4'>
            Enter the amount of money you’d like to add to this event wallet and choose your payment
            method.
          </div>
        </div>
        <div className='w-3/5'>
          <CurrencyAmountConverter
            topUpAmount={topUpAmount}
            onChange={(e) => handleAmountChange(e)}
          />
          {paymentMethods.length ? (
            <div className='text-custom-secondary text-sm pt-8'>Payment Method</div>
          ) : null}
          <div className='pt-4'>
            {paymentMethods.length ? (
              <>
                <hr className='border-gray-300 pb-2' />
                {paymentMethods.map((card: PaymentMethod, index) => (
                  <React.Fragment key={card.uuid}>
                    <PaymentMethodRowDisplay
                      id={card.uuid}
                      defaultValue={card.default}
                      cardTypeIcon={getCreditCardIconByType(
                        card.info.spreedlyPaymentMethod.card_type,
                      )}
                      number={replaceSpreedlyHideString(card.info.spreedlyPaymentMethod.number)}
                      expiryMonth={String(card.info.spreedlyPaymentMethod.month).padStart(2, '0')}
                      expiryYear={card.info.spreedlyPaymentMethod.year}
                      setDefaultCallback={changeDefaultPayment}
                      onSelect={() => setSelectedPaymentMethodId(card.uuid)}
                      showEllipsis={false}
                    />
                    {index < paymentMethods.length - 1 && <hr className='border-gray-300' />}
                  </React.Fragment>
                ))}
                <hr className='border-gray-300' />
              </>
            ) : (
              <>
                <div className='text-yellow-500 mb-3'>There is no payment method attached</div>
                <div onClick={() => navigate(`/events/${eventId}/payment-methods`)}>
                  <span className='text-blue-600 font-semibold cursor-pointer'>Click here</span> to
                  attach a payment method
                </div>
              </>
            )}
          </div>
          <PayNowModal
            modal={isPayModalOpen}
            toggle={() => setIsPayModalOpen(!isPayModalOpen)}
            onClosed={() => setIsPayModalOpen(!isPayModalOpen)}
            instantPayCallback={instantPayWithCardInfo}
            payWithExistingCard={handlePayButtonOnClick}
            topUpAmount={topUpAmount}
            setTopUpAmount={setTopUpMount}
            payEnabled={payEnabled}
            newWalletBalance={balance}
          />

          <div className='flex justify-between pt-4'>
            <div className='flex gap-2'>
              <div className='text-custom-secondary'> New Wallet Balance:</div>
              <div className='text-custom-primary font-medium'>{balance}</div>
            </div>
            <Button
              onClick={() => setIsPayModalOpen(true)}
              isLoading={paymentIsLoading}
              className='text-custom-primary bg-intelli-mainColor py-2 px-4 rounded-md ml-3'
              disabled={topUpAmount < minimumAmount || !payEnabled}
            >
              Add Funds
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );

  const renderMobileView = () => (
    <div className='flex flex-col h-screen'>
      <div className='overflow-auto'>
        <div className=''>
          <div className='text-custom-primary text-xl font-bold pb-2'>Manage Funds</div>
          <div className='text-custom-secondary text-lg pb-4'>
            Enter the amount of money you’d like to add to this event wallet and choose your payment
            method.
          </div>
          <CurrencyAmountConverter
            topUpAmount={topUpAmount}
            onChange={(e) => handleAmountChange(e)}
          />
          <div
            className={`text-custom-secondary ${isMobile ? 'text-lg mt-4' : 'text-sm pt-4'} ${
              !paymentMethods.length && 'hidden'
            }`}
          >
            Payment Method
          </div>
        </div>
      </div>
      {paymentMethods.length ? (
        <PaymentMethodSelector
          paymentMethods={paymentMethods}
          selectedPaymentMethodId={selectedPaymentMethodId!}
          setSelectedPaymentMethodId={setSelectedPaymentMethodId}
        />
      ) : (
        <>
          <div className='text-yellow-500 my-3 text-lg mt-5'>
            No payment methods have been added yet
          </div>
          <div onClick={() => navigate(`/events/${eventId}/payment-methods`)} className='text-lg'>
            <span className='text-blue-600 font-semibold cursor-pointer'>Click here</span> to add
            your payment method
          </div>
        </>
      )}

      <div
        className={`fixed bottom-0 left-0 w-full bg-white shadow-lg p-4 rounded-2xl space-y-4 ${
          !paymentMethods.length && 'hidden'
        }`}
      >
        <div className='text-lg font-semibold text-gray-700'>
          New Wallet Balance: &nbsp;
          <span className='text-black'>{balanceAfterTopUp}</span>
        </div>
        <Button
          color='primary'
          onClick={handlePayButtonOnClick}
          isLoading={paymentIsLoading}
          disabled={topUpAmount < minimumAmount || !payEnabled}
          className='w-full rounded-lg text-lg font-bold py-2 disabled:bg-gray-400 disabled:border-0'
        >
          {paymentIsLoading ? <Spinner /> : 'Pay Now'}
        </Button>
      </div>
    </div>
  );

  return isMobile ? renderMobileView() : renderDesktopView();
};

export default ManageFunds;
