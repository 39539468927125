import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup } from 'reactstrap';
import { useSelector } from 'react-redux';

import {
  buildCurrencyBalance,
  getCurrencySymbolForCurrencyCode,
  getMinimumTopUpAmount,
} from '../../../helpers/currency';

import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { selectEventCurrency } from 'store';

type CurrencyAmountConverterProps = {
  topUpAmount: number;
  onChange: (value: number, event: ChangeEvent<HTMLInputElement>) => void;
};

const CurrencyAmountConverter: FC<CurrencyAmountConverterProps> = ({ topUpAmount, onChange }) => {
  const isMobile = useMediaQuery();
  const currency = useSelector(selectEventCurrency);
  const { t } = useTranslation();
  const currencySymbol = getCurrencySymbolForCurrencyCode(currency);

  const handleKeyPress = (event: any) => {
    if (!/\d/.test(event.key) || event.key === '-') {
      event.preventDefault();
    }
  };

  const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (Number(value) >= 0) {
      onChange(Number(value === '' ? 0 : parseInt(value, 10)), event);
    }
  };

  const minimumAmount = getMinimumTopUpAmount(currency);

  return (
    <div className='pt-0'>
      <div className={`${isMobile ? 'block' : 'flex'}`}>
        {isMobile ? (
          <div className='w-full md:w-2/5'>
            <InputGroup className='w-full'>
              <div className='text-lg text-custom-secondary pb-2'>
                Enter Amount (min. {minimumAmount})
              </div>
              <div className='relative w-full'>
                <Input
                  type='tel'
                  pattern='[0-9]*'
                  value={topUpAmount}
                  onKeyPress={handleKeyPress}
                  onChange={handleNumberChange}
                  className='w-full h-14 text-2xl pr-10 rounded-lg'
                />
                <span className='absolute inset-y-0 right-2 flex items-center text-2xl text-custom-primary '>
                  {currency}
                </span>
              </div>
            </InputGroup>
            <div className='bg-gray-100 w-full rounded-lg px-3 py-2 mt-3 flex flex-row justify-between'>
              <div className='text-lg'>Event Currency</div>
              <div className='text-lg font-bold'>
                {currencySymbol}
                {topUpAmount.toFixed(2)}
              </div>
            </div>
          </div>
        ) : (
          <div className='w-full md:w-2/5'>
            <InputGroup className='w-full'>
              <div className='text-sm text-custom-secondary pb-2'>{t('TopUpModal.amount')}</div>
              <div className='relative w-full'>
                <Input
                  type='tel'
                  pattern='[0-9]*'
                  value={topUpAmount}
                  onKeyPress={handleKeyPress}
                  onChange={handleNumberChange}
                  className='w-full pr-10'
                />
                <span className='absolute inset-y-0 right-2 flex items-center text-custom-secondary'>
                  {currency}
                </span>
              </div>
            </InputGroup>
          </div>
        )}

        {!isMobile && (
          <div className='w-full md:w-3/5 flex items-center'>
            <div className='p-1 pr-1'>
              <div className='text-3xl px-3 text-center'>=</div>
            </div>
            <div className='w-full inline-block'>
              <InputGroup className='text-custom-primary flex flex-col bg-intelli-background px-3 py-1 rounded-md'>
                <div className='text-sm text-custom-secondary'>{t('TopUpModal.eventCurrency')}</div>
                <div>
                  <span className='mr-1'>{currencySymbol}</span>
                  <span>{topUpAmount.toFixed(2)}</span>
                </div>
              </InputGroup>
            </div>
          </div>
        )}
      </div>
      <label
        className={
          isMobile ? 'pt-2 text-custom-secondary hidden' : 'pt-3 text-left text-custom-secondary'
        }
      >
        A minimum of {buildCurrencyBalance(currency, currencySymbol, minimumAmount)} must be added.
      </label>
    </div>
  );
};

export default CurrencyAmountConverter;
