import React, { useState, useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Input, FormGroup } from 'reactstrap';
import InputMask from 'react-input-mask';

import 'react-credit-cards-2/dist/es/styles-compiled.css';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import usePayments from 'data/usePayments';
import { creditCardInfo } from 'components/AddPaymentMethodModal/AddPaymentMethodModal';

interface AddCreditCardInputsProps {
  autoTopUp?: boolean;
  setSaveCard?: (value: boolean) => void;
  toggle?: () => void;
  methods: any;
}

function AddCreditCardInputs({
  autoTopUp = false,
  setSaveCard,
  toggle,
  methods,
}: AddCreditCardInputsProps) {
  const { t } = useTranslation();
  const { status } = usePayments();
  const inputRef = useRef<HTMLInputElement>(null);
  const [paymentMethodAdded, setPaymentMethodAdded] = useState(false);
  const isMobile = useMediaQuery();

  // If autoTopUp is set then we need to set the saveCard state to true
  useEffect(() => {
    setSaveCard && setSaveCard(autoTopUp);
  }, [autoTopUp, setSaveCard]);

  useEffect(() => {
    const defaultValues = {} as creditCardInfo;
    defaultValues.cardNumber = '';
    defaultValues.cardHolderName = '';
    defaultValues.expiryDate = '';
    defaultValues.cvv = '';
    methods.reset(defaultValues);
  }, [methods]);

  // Prevent scroll from changing input value
  function handleWheel() {
    if (document?.activeElement === inputRef.current) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  const preventPasteNegativeOrDecimal = (e: any) => {
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const pastedData = parseFloat(clipboardData.getData('text'));

    if (pastedData < 0 || pastedData % 1 !== 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    // Close Screen once payment method added
    if (status !== 'loading' && paymentMethodAdded) {
      setPaymentMethodAdded(false);
      methods.reset();
      toggle && toggle();
    }
  }, [status, toggle, paymentMethodAdded, methods]);
  return (
    <>
      <Controller
        control={methods.control}
        render={({ field: { onChange, value, name } }) => (
          <FormGroup>
            <Label for='cardNumber'>{t('addPaymentMethodModal.cardNumber')}</Label>
            <Input
              id='cardNumber'
              name={name}
              placeholder={t('addPaymentMethodModal.enterCardNumber')}
              type='number'
              value={value}
              onWheel={() => handleWheel()}
              onChange={onChange}
              innerRef={inputRef}
              onKeyDown={(e) => {
                if (e.key === '.') {
                  e.preventDefault();
                }
              }}
              onInput={(e: any) => {
                e.target.value = Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null;
              }}
              onPaste={(e) => preventPasteNegativeOrDecimal(e)}
            />
            {methods.formState.errors.cardNumber && (
              <Label className='text-red-600'>{methods.formState.errors.cardNumber.message}</Label>
            )}
          </FormGroup>
        )}
        name='cardNumber'
      />
      <Controller
        control={methods.control}
        render={({ field: { onChange, value, name } }) => (
          <FormGroup>
            <Label for='cardHolderName'>{t('addPaymentMethodModal.name')}</Label>
            <Input
              id='cardHolderName'
              name={name}
              placeholder={t('addPaymentMethodModal.fullName')}
              type='search'
              value={value}
              onChange={onChange}
            />
            {methods.formState.errors.cardHolderName && (
              <Label className='text-red-600'>
                {methods.formState.errors.cardHolderName.message}
              </Label>
            )}
          </FormGroup>
        )}
        name='cardHolderName'
      />
      <div className='flex gap-4 w-full'>
        <Controller
          control={methods.control}
          name='expiryDate'
          render={({ field: { onChange, value } }) => (
            <FormGroup className='flex-1'>
              <Label for='expiryDate'>{t('addPaymentMethodModal.expiryDate')}</Label>
              <InputMask mask='99/99' maskPlaceholder='' value={value} onChange={onChange}>
                {
                  //@ts-ignore
                  () => (
                    <Input
                      id='expiryDate'
                      name='expiryDate'
                      placeholder={
                        isMobile
                          ? t('addPaymentMethodModal.expiryPlaceHolderMobile')
                          : t('addPaymentMethodModal.expiryPlaceHolder')
                      }
                      type='text'
                      className='w-full' // Ensure this input takes full width
                    />
                  )
                }
              </InputMask>
              {methods.formState.errors.expiryDate && (
                <Label className='text-red-600'>
                  {methods.formState.errors.expiryDate.message}
                </Label>
              )}
            </FormGroup>
          )}
        />

        <Controller
          control={methods.control}
          render={({ field: { onChange, value, name } }) => (
            <FormGroup className='flex-1'>
              <Label for='cvv'>{t('addPaymentMethodModal.cvv')}</Label>
              <Input
                id='cvv'
                name={name}
                placeholder={t('addPaymentMethodModal.cvvPlaceHolder')}
                type='search'
                value={value}
                onChange={onChange}
                className='w-full' // Ensure this input takes full width
              />
              {methods.formState.errors.cvv && (
                <Label className='text-red-600'>{methods.formState.errors.cvv.message}</Label>
              )}
            </FormGroup>
          )}
          name='cvv'
        />
      </div>
    </>
  );
}

export default AddCreditCardInputs;
