import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Row } from 'reactstrap';

import { Button } from '../components';
import { useLoginForm } from '../hooks';

import { ControlledTextField } from 'components';
import { Navigation } from 'navigation';

interface MobileViewProps {
  checkLoggedIn: () => void;
  loginWithEmailPassword: any;
  isLoading: boolean;
}

const MobileViewComponent: FC<MobileViewProps> = ({
  checkLoggedIn,
  loginWithEmailPassword,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { control, onSubmit } = useLoginForm();

  return (
    <>
      <Row className='text-center pb-3'>
        <Row className='pb-2'>
          <div className='font-medium text-custom-primary text-2xl'>{t('loginHeader.header')}</div>
        </Row>
        <div className='font-light'>
          <span className='text-custom-secondary'>{t('Login.noAccount') + ' '}</span>
          <span className='font-light text-[#079286] visited:text-[#079286] underline'>
            <Link className='link-custom' to={Navigation.SIGN_UP}>
              {t('SignUpHeader.header')}
            </Link>
          </span>
        </div>
      </Row>
      <Form onSubmit={onSubmit(loginWithEmailPassword, checkLoggedIn)}>
        <FormGroup>
          <ControlledTextField
            label={t('Login.placeholder.emailAddress')}
            fieldName='loginEmail'
            type='email'
            control={control}
          />
        </FormGroup>
        <FormGroup>
          <ControlledTextField
            label={t('Login.placeholder.password')}
            fieldName='loginPassword'
            type='password'
            control={control}
          />
        </FormGroup>
        <Row className='link-custom underline text-[#6F7FAF]'>
          <Link className='link-custom text-sm' to={Navigation.RESET_PASSWORD}>
            {t('Login.link.forgotPassword')}
          </Link>
        </Row>
        <div className='fixed bottom-0 left-0 right-0 p-3'>
          <FormGroup>
            <Button
              block
              className='mt-3 bg-intelli-mainColor py-3'
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              {t('Login.button.logIn')}
            </Button>
          </FormGroup>
          <div className='form-support flex items-center justify-center text-[1.2rem] mb-4 text-center'>
            <a
              href={Navigation.SUPPORT}
              target='_blank'
              rel='noreferrer'
              className='fs--1 font-[Open Sans] !text-[#748194] leading-6 font-extralight'
            >
              {t('AuthLayout.troubleLoggingIn')}
              <br />
              {t('AuthLayout.contactSupport')}
              <span className='d-inline-block ml-1'>&rarr;</span>
            </a>
          </div>
        </div>
      </Form>
    </>
  );
};

export default MobileViewComponent;
