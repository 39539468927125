import { Config } from '../Config';

import { ClientConfig, SecuredApi } from './Api';
import type { ClaimWristbandRequestBody } from './types/OrdersApiTypes';

import { ClaimType, Order, TopupType, Vendor } from 'data';
import { paths } from 'data/constants';

interface OrdersApi {
  createTopUpOrder: (topUpFolioUuid: string, orderInfo: any) => Promise<unknown>;
  getOrderById: (orderId: string) => Promise<Order>;
  getVendorByFolioId: (orderId: string) => Promise<Vendor>;
  claimWristband: (body: ClaimWristbandRequestBody, folioId: string) => Promise<any>;
  checkIfWristbandExists: (
    body: ClaimWristbandRequestBody,
    folioId: string,
    claimType: ClaimType,
  ) => Promise<{ existed: boolean }>;
  refundHistory: (accountId: string, eventId: string) => Promise<any>;
  getCurrency: (folioId: string) => Promise<any>;
  getTopupTypeById: (folioId: string, topupTypeId: string) => Promise<TopupType>;
  getOrdersByRfid: (rfidUid: string) => Promise<any>;
}

export class OrdersApiImpl extends SecuredApi implements OrdersApi {
  authtokenTTL = 180;
  rfidSearch = '';
  clientConfig = (): ClientConfig => {
    return {
      baseURL: Config.ORDERS,
      timeout: 15000,
    };
  };

  getProducts = async (topUpFolioUuid: string): Promise<any> => {
    const response = await this.client.get(paths.getProducts(topUpFolioUuid));
    return response.data;
  };

  createTopUpOrder = async (topUpFolioUuid: string, body: any): Promise<Order> => {
    const response = await this.client.post(paths.createOrder(topUpFolioUuid), body);
    return response.data;
  };

  getOrderById = async (orderId: string) => {
    const response = await this.client.get(paths.fetchOrderById(orderId));
    return response.data?.entry;
  };

  getVendorByFolioId = async (folioId: string): Promise<Vendor> => {
    const response = await this.client.get(paths.getVendorByFolioId(folioId));
    return response.data;
  };

  getWalletByFolioId = async (eventFolioId: string): Promise<unknown> => {
    const response = await this.client.get(paths.getWalletByFolioId(eventFolioId));
    return response.data;
  };

  getOrdersByRfid = async (rfidUid: string): Promise<any> => {
    this.rfidSearch = rfidUid;
    const response = await this.client.get(paths.getOrdersByRfid(rfidUid));
    return response.data;
  };

  claimWristband = async (body: ClaimWristbandRequestBody, folioId: string): Promise<any> => {
    const response = await this.client.put(paths.claimWristband(folioId), body);
    return response.data;
  };

  checkIfWristbandExists = async (
    { rfidUid, securityCode }: ClaimWristbandRequestBody,
    folioId: string,
    claimType: ClaimType,
  ): Promise<{ existed: boolean }> => {
    if (claimType === 'Security Code Only') {
      // Security Code only event
      const response = await this.client.get(
        paths.checkIfWristbandExistsSecurityCode(securityCode!, folioId),
      );
      return response.data;
    } else {
      const response = await this.client.get(
        paths.checkIfWristbandExists(rfidUid!, securityCode!, folioId),
      );
      return response.data;
    }
  };

  sendEmailReceipt = async (orderId: string): Promise<any> => {
    const response = await this.client.get(paths.sendEmailReceipt(orderId));
    return response.data;
  };
  refundHistory = async (accountId: string, eventId: string) => {
    try {
      const response = await this.client.get(paths.refundHistory(accountId, eventId));

      return response;
    } catch (err) {
      console.log('Failed to retrieve History', err);
    }
  };
  getCurrency = async (folioId: string) => {
    const response = await this.client.get(paths.getCurrency(folioId));

    return response.data;
  };

  getTopupTypeById = async (folioId: string, topupTypeId: string) => {
    const response = await this.client.get(paths.getTopupTypes(folioId, topupTypeId));

    return response.data;
  };

  freezeWallet = async (eventId: string, walletId: string, accountId: string): Promise<unknown> => {
    const response = await this.client.put(`/events/${eventId}/wallet/${walletId}/freeze`, {
      customerId: accountId,
    });

    return response.data;
  };

  unfreezeWallet = async (
    eventId: string,
    walletId: string,
    accountId: string,
  ): Promise<unknown> => {
    const response = await this.client.put(`/events/${eventId}/wallet/${walletId}/unfreeze`, {
      customerId: accountId,
    });

    return response.data;
  };
}

export default OrdersApi;
