import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import PaymentMethodRowDisplay from './PaymentMethodRowDisplay';

import { Heading, AddPaymentMethodModal, Box } from 'components';
import { PaymentMethod } from 'data/model';
import { replaceSpreedlyHideString, getCreditCardIconByType } from 'helpers/creditCardUtils';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import useEventUri from 'hooks/useEventUri';

interface PaymentMethodsTableProps {
  paymentMethods: any[];
  changeDefaultCallback: (id: string, eventUri: any) => void;
  deleteMethodCallback?: (id: string, eventUri: any) => void;
}

const PaymentMethodsTable = ({
  paymentMethods,
  changeDefaultCallback,
  deleteMethodCallback,
}: PaymentMethodsTableProps) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string | null>(null);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState<string | null>(
    paymentMethods.find((method) => method.default)?.uuid,
  );
  const eventUri = useEventUri();

  const isMobile = useMediaQuery();

  const handleSelectPaymentMethod = (id: string) => {
    setSelectedPaymentMethodId(id);
  };

  const handleSetDefault = () => {
    if (selectedPaymentMethodId) {
      changeDefaultCallback(selectedPaymentMethodId, eventUri);
      setDefaultPaymentMethodId(selectedPaymentMethodId);
      setSelectedPaymentMethodId(null);
    }
  };

  const renderPaymentMethods = () =>
    paymentMethods.map((card: PaymentMethod, index) => (
      <React.Fragment key={card.uuid}>
        <PaymentMethodRowDisplay
          id={card.uuid}
          defaultValue={card.default}
          setDefaultCallback={changeDefaultCallback}
          cardTypeIcon={getCreditCardIconByType(card.info.spreedlyPaymentMethod.card_type)}
          number={replaceSpreedlyHideString(card.info.spreedlyPaymentMethod.number)}
          expiryMonth={String(card.info.spreedlyPaymentMethod.month).padStart(2, '0')}
          expiryYear={card.info.spreedlyPaymentMethod.year}
          deleteMethodCallback={deleteMethodCallback ? deleteMethodCallback : undefined}
          onSelect={() => handleSelectPaymentMethod(card.uuid)}
        />
        {index < paymentMethods.length - 1 && <hr className='border-gray-300' />}
      </React.Fragment>
    ));

  const renderEmptyState = () => (
    <div className='flex flex-col justify-center items-center'>
      <Heading headingLevel='h3' className='text-lg text-custom-primary font-normal pt-2'>
        No payment methods have been added yet
      </Heading>
      <Heading headingLevel='h2' className='text-base text-custom-secondary font-normal pt-2'>
        Add your payment method to start making payments.
      </Heading>
    </div>
  );

  const renderDesktopView = () => (
    <Box>
      <div className='flex gap-4'>
        <div className='w-2/5'>
          <div className='text-custom-primary text-2xl font-bold pl-6 pb-2'>My Payment Methods</div>
          <div className='text-[#6f7faf] text-base pl-6 pb-4'>
            This will allow you to spend directly from your card.
          </div>
        </div>
        <div className='w-3/5'>
          <div
            className={`${
              paymentMethods.length ? '' : 'flex flex-col items-center justify-center min-h-[500px]'
            }`}
          >
            {paymentMethods.length ? renderPaymentMethods() : renderEmptyState()}
            {selectedPaymentMethodId && selectedPaymentMethodId !== defaultPaymentMethodId && (
              <div className='flex justify-end'>
                <button
                  className='mt-4 py-2 px-3 bg-intelli-primaryNew rounded'
                  onClick={handleSetDefault}
                >
                  Set as Default
                </button>
              </div>
            )}
            <div
              className={`flex ${paymentMethods.length > 0 ? 'justify-end' : 'justify-center'} `}
            >
              <div
                className='text-intelli-secondary text-base font-medium pt-4 hover:text-intelli-primary cursor-pointer'
                onClick={toggleModal}
              >
                Add Payment Method <FontAwesomeIcon icon={faPlus} className='pl-2' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddPaymentMethodModal
        toggle={toggleModal}
        modal={showModal}
        onClosed={toggleModal}
        addCard={true}
      />
    </Box>
  );

  const renderMobileView = () => (
    <Box className='w-full shadow-[0px_4px_8px_8px_rgba(146,146,210,0.1)] p-[1.5rem] bg-[#FFFFFF] rounded-lg'>
      {paymentMethods.length ? (
        <div className='pt-4'>{renderPaymentMethods()}</div>
      ) : (
        <div className='pb-3'>
          <Heading headingLevel='h3' className='text-[18px] text-custom-primary font-normal'>
            Attached Payment Methods
          </Heading>
          <hr className='w-70 mx-auto my-3 border-t border-gray-300' />
          <p className='mt-3'>
            This will allow you to spend directly from your card so you don&apos;t need to Top-up
            funds to your Event Balance throughout the event.
          </p>
        </div>
      )}
      <div className='p-2 justify-end flex flex-col'>
        {selectedPaymentMethodId && selectedPaymentMethodId !== defaultPaymentMethodId && (
          <button className='mt-4 p-2 bg-primary text-white rounded' onClick={handleSetDefault}>
            Set as Default
          </button>
        )}
        <button className='w-full py-4 text-intelli-secondary' onClick={toggleModal}>
          Add Payment Method <FontAwesomeIcon icon={faPlus} className='pl-2' />
        </button>
      </div>
      <AddPaymentMethodModal
        toggle={toggleModal}
        modal={showModal}
        onClosed={toggleModal}
        addCard={true}
      />
    </Box>
  );

  return isMobile ? renderMobileView() : renderDesktopView();
};

export default PaymentMethodsTable;
