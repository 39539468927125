import moment from 'moment';

import { RefundFormConfiguration } from 'data';

export const isRefundFormInAvailabilityWindow = (
  eventRefundConfiguration: RefundFormConfiguration | undefined,
) => {
  if (eventRefundConfiguration && isRefundEnabledForEvent(eventRefundConfiguration)) {
    return moment().isBetween(
      eventRefundConfiguration?.refundStartDate,
      eventRefundConfiguration?.refundEndDate,
    );
  }

  return false;
};

export const isRefundEnabledForEvent = (
  eventRefundConfiguration: RefundFormConfiguration | undefined,
) => {
  if (eventRefundConfiguration && eventRefundConfiguration.enableRefundForm) {
    return true;
  }

  return false;
};

export const isRefundFormAvailable = (
  eventRefundConfiguration: RefundFormConfiguration | undefined,
) => {
  return (
    isRefundEnabledForEvent(eventRefundConfiguration) &&
    isRefundFormInAvailabilityWindow(eventRefundConfiguration)
  );
};

export const getRefundStartDate = (eventRefundConfiguration: RefundFormConfiguration) => {
  return moment(eventRefundConfiguration?.refundStartDate).format('YYYY MMM DD');
};

export const getRefundEndDate = (eventRefundConfiguration: RefundFormConfiguration) => {
  const refundDateTime =
    eventRefundConfiguration?.refundEndDateTime || eventRefundConfiguration?.refundEndDate;
  const format = eventRefundConfiguration?.refundEndDateTime ? 'YYYY MMM DD HH:mm' : 'YYYY MMM DD';

  return refundDateTime ? moment(refundDateTime).format(format) : '';
};
