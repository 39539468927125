import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

import EmailVerify from '../../EmailVerify/EmailVerify';
import PasswordReset from '../../PasswordResetPage/PasswordReset';

const AuthActions: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  // Extract query parameters
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get('mode');
  const oobCode = searchParams.get('oobCode');

  // Validate that required parameters exist
  if (!mode || !oobCode) {
    return (
      <div className='text-center'>
        <Alert
          color='danger'
          className='text-left border-left border-danger mb-4'
          style={{
            borderLeft: '4px solid #dc3545',
            backgroundColor: '#fff1f0',
          }}
        >
          <div className='d-flex align-items-center mb-2'>
            <h4 className='alert-heading mb-0'>{t('authActions.invalidLink')}</h4>
          </div>
          <p className='mb-0'>{t('authActions.missingParameters')}</p>
        </Alert>
      </div>
    );
  }

  // Render the appropriate component based on the mode
  switch (mode) {
    case 'resetPassword':
      return <PasswordReset />;

    case 'verifyEmail':
      return <EmailVerify />;

    default:
      return (
        <div className='text-center'>
          <Alert
            color='danger'
            className='text-left border-left border-danger mb-4'
            style={{
              borderLeft: '4px solid #dc3545',
              backgroundColor: '#fff1f0',
            }}
          >
            <div className='d-flex align-items-center mb-2'>
              <h4 className='alert-heading mb-0'>{t('authActions.unsupportedAction')}</h4>
            </div>
            <p className='mb-0'>{t('authActions.invalidMode')}</p>
          </Alert>
        </div>
      );
  }
};

export default AuthActions;
