import { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { resourceToUri } from 'jwt-cert-sdk';

import { useAppDispatch, useAppSelector } from '../store/hook';
import { updateWalletBalance } from '../store/ledger/ledgerReducer';
import { freezeWallet, unfreezeWallet, fetchWallet } from '../store/orders/ordersReducer';

import { useData } from './ContentProvider';
// import { logError } from './api/LoggingApi';
import { WalletInfo } from './api/types/LedgerApiTypes';
import { useEventServiceIdentity } from './api/EventApi';

import { selectEventSelected, selectEventSelectedLedgerId } from 'store';
import { selectStatus, selectUpdatingStatus } from 'store/ledger/ledgerSelector';
import { selectCurrentWallet } from 'store/orders/ordersSelectors';

/**
 * Fetch details for a given ledgerId and walletId
 */
const useLedger = () => {
  const { securedApi } = useData();
  const dispatch = useAppDispatch();
  const selectedEventLedgerId = useSelector(selectEventSelectedLedgerId, shallowEqual);
  const selectedEvent = useSelector(selectEventSelected, shallowEqual);
  const currentWallet: any = useAppSelector(selectCurrentWallet);
  const isLoading = useAppSelector(selectStatus);
  const isUpdatingWallet = useAppSelector(selectUpdatingStatus);
  const eventServiceIdentity = useEventServiceIdentity();

  const currenWalletId = currentWallet?.wallet?.id.toString() ?? '';
  const currentWalletBalance = currentWallet?.walletBalance / 100 ?? 0;
  const currentWalletRFID = currentWallet?.rfid ? currentWallet?.rfid : '';
  const isWristbandFrozen = currentWallet?.wallet?.isFrozen ? true : false;

  const eventUri = eventServiceIdentity
    ? resourceToUri({
        resourceId: selectedEvent.uuid,
        serviceId: eventServiceIdentity?.sub,
        resourceType: 'cb:event',
      })
    : null;

  const updateWalletBalanceOptimistically = useCallback(
    async (newWalletBalance: number) => {
      dispatch(updateWalletBalance({ ledgerId: selectedEventLedgerId, newWalletBalance }));
    },
    [dispatch, selectedEventLedgerId],
  );

  const fetchEventWallet = useCallback(
    async (eventId: string) => {
      const { payload } = await dispatch(fetchWallet({ eventId, securedApi }));
      return payload as WalletInfo;
    },
    [dispatch, securedApi],
  );

  const freezeWalletAction = useCallback(
    async (eventId: string, walletId: string, accountId: string) => {
      const { payload } = await dispatch(
        freezeWallet({ eventId: eventUri, walletId, accountId, securedApi }),
      );

      return payload as any;
    },
    [dispatch, eventUri, securedApi],
  );

  const unfreezeWalletAction = useCallback(
    async (eventId: string, walletId: string, accountId: string) => {
      const { payload } = await dispatch(
        unfreezeWallet({ eventId: eventUri, walletId, accountId, securedApi }),
      );

      return payload as any;
    },
    [dispatch, eventUri, securedApi],
  );

  const fetchEventWalletAndDetailsOfSelectedEvent = useCallback(async () => {
    let response;
    if (eventUri) {
      response = await fetchEventWallet(eventUri);
    }
    return response;
  }, [eventUri, fetchEventWallet]);

  return {
    isLoading,
    currentWallet,
    currenWalletId,
    currentWalletBalance,
    currentWalletRFID,
    isUpdatingWallet,
    fetchEventWallet,
    updateWalletBalanceOptimistically,
    fetchEventWalletAndDetailsOfSelectedEvent,
    freezeWalletAction,
    unfreezeWalletAction,
    isWristbandFrozen,
  };
};

export default useLedger;
