import React, { FC } from 'react';

import { SettingsHeader } from './components';

import { UserIcon } from 'components/icons';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { Navigation } from 'navigation';
import { InnerLayout } from 'pages';

const links = [
  {
    name: 'Account Information',
    to: Navigation.USER_PROFILE,
    target: '',
    icon: <UserIcon />,
  },
  // {
  //   name: 'Payment Methods',
  //   to: Navigation.PAYMENT_METHODS,
  //   target: '',
  //   icon: <WalletIcon />,
  // },
];

const SettingsLayout: FC = () => {
  const isMobile = useMediaQuery();

  return (
    <>
      {!isMobile && <SettingsHeader />}
      <InnerLayout links={links} mobileHeader={<SettingsHeader />} />
    </>
  );
};

export default SettingsLayout;
