import React, { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { usePasswordReset } from '../../hooks/usePasswordReset';

import { Button } from 'components';
import { Navigation } from 'navigation';

const PasswordReset: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formError, setFormError] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const oobCode = new URLSearchParams(location.search).get('oobCode');
  const continueUrl = new URLSearchParams(location.search).get('continueUrl');

  console.log('continueUrl:', continueUrl);

  const { email, loading, error, success, isValidCode, resetPassword } = usePasswordReset(oobCode);

  const handlePasswordReset = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setFormError(null);

      // Validate passwords
      if (newPassword !== confirmPassword) {
        setFormError(t('passwordReset.passwordsDoNotMatch'));
        return;
      }

      if (newPassword.length < 8) {
        setFormError(t('passwordReset.passwordTooShort'));
        return;
      }

      const isSuccess = await resetPassword(newPassword);

      if (isSuccess) {
        // Redirect to continue URL if provided, otherwise go to login page
        setTimeout(() => {
          console.log('Redirecting to:', continueUrl || Navigation.LOGIN);
          if (continueUrl) {
            window.location.href = continueUrl;
          } else {
            navigate(Navigation.LOGIN, { state: { passwordReset: true } });
          }
        }, 2000);
      }
    },
    [newPassword, confirmPassword, resetPassword, t, continueUrl, navigate],
  );

  if (loading && !email) {
    return (
      <div className='text-center'>
        <h3 className='mb-3'>{t('passwordReset.verifyLoadingTitle')}</h3>
        <p className='text-muted'>{t('passwordReset.verifyLoadingDescription')}</p>
      </div>
    );
  }

  if ((error && !isValidCode) || !oobCode) {
    return (
      <div className='text-center'>
        <Alert
          color='danger'
          className='text-left border-left border-danger mb-4'
          style={{
            borderLeft: '4px solid #dc3545',
            backgroundColor: '#fff1f0',
          }}
        >
          <div className='d-flex align-items-center mb-2'>
            <h4 className='alert-heading mb-0'>{t('passwordReset.invalidLinkTitle')}</h4>
          </div>
          <p className='mb-0'>{error || t('passwordReset.invalidLinkDescription')}</p>
        </Alert>
      </div>
    );
  }

  if (success) {
    return (
      <div className='text-center'>
        <Alert
          color='success'
          className='text-left border-left mb-4'
          style={{
            borderLeft: '4px solid #28a745',
            backgroundColor: '#f0fff4',
          }}
        >
          <div className='d-flex align-items-center mb-2'>
            <h4 className='alert-heading mb-0'>{t('passwordReset.successTitle')}</h4>
          </div>
          <p className='mb-0'>{t('passwordReset.successDescription')}</p>
        </Alert>
        <p className='mb-3'>{t('passwordReset.successInstruction')}</p>
      </div>
    );
  }

  // Default form view
  return (
    <div>
      <h3 className='text-center mb-3'>{t('passwordReset.formTitle')}</h3>
      {email && (
        <p className='text-center text-muted mb-4'>
          {t('passwordReset.forEmail')} {email}
        </p>
      )}

      <form onSubmit={handlePasswordReset}>
        <div className='form-group mb-3'>
          <label htmlFor='password'>{t('passwordReset.newPassword')}</label>
          <input
            id='password'
            type='password'
            className='form-control'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            minLength={8}
            autoComplete='new-password'
          />
        </div>

        <div className='form-group mb-3'>
          <label htmlFor='confirmPassword'>{t('passwordReset.confirmPassword')}</label>
          <input
            id='confirmPassword'
            type='password'
            className='form-control'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            minLength={8}
            autoComplete='new-password'
          />
        </div>

        {formError && (
          <Alert
            color='danger'
            className='text-left border-left border-danger my-3'
            style={{
              borderLeft: '4px solid #dc3545',
              backgroundColor: '#fff1f0',
            }}
          >
            {formError}
          </Alert>
        )}

        {error && (
          <Alert
            color='danger'
            className='text-left border-left border-danger my-3'
            style={{
              borderLeft: '4px solid #dc3545',
              backgroundColor: '#fff1f0',
            }}
          >
            {error}
          </Alert>
        )}

        <div className='text-center mt-4'>
          <Button
            type='submit'
            disabled={loading}
            className='btn btn-primary d-inline-flex align-items-center px-4 text-white'
          >
            {loading ? t('passwordReset.resettingButton') : t('passwordReset.resetButton')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PasswordReset;
