import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { PrivateRoute, RestrictedRoute } from 'hooks/Firebase';
import { Navigation } from 'navigation';
import * as Pages from 'pages';
import RefundRequest from 'pages/RefundRequest/RefundRequest';
import { RefundProvider } from 'pages/RefundRequest/RefundContext';
import AuthActions from 'pages/auth/AuthActions';

const Routing = () => (
  <Routes>
    <Route path={Navigation.HOME} element={<Pages.Layout />}>
      <Route path={Navigation.HOME} element={<Navigate to={Navigation.EVENTS} />} />
      <Route element={<PrivateRoute />}>
        <Route element={<Pages.InnerLayout />}>
          <Route path={Navigation.EVENTS} element={<Pages.Home />} />
          <Route element={<Pages.EventLayout />}>
            <Route path={Navigation.MANAGE_FUNDS} element={<Pages.ManageFunds />} />{' '}
            {/* Temporary hidden MyTickets before real event */}
            {/* <Route path={Navigation.TICKETS} element={<Pages.MyTickets />} /> */}
            <Route path={Navigation.TRANSACTIONS} element={<Pages.Transactions />} />
            <Route path={Navigation.PAYMENT_METHODS} element={<Pages.PaymentMethods />} />
            <Route
              path={Navigation.REFUND_REQUEST}
              element={
                <RefundProvider>
                  <RefundRequest />
                </RefundProvider>
              }
            />
          </Route>
          <Route
            path={Navigation.PAYMENT_METHOD_DETAILS}
            element={<Pages.PaymentMethodDetails />}
          />
          <Route path={Navigation.TRANSACTION_DETAILS} element={<Pages.TransactionDetails />} />
          <Route path={Navigation.MANAGE_PROFILE} element={<Pages.ManageProfileInfo />} />
        </Route>
        <Route path={Navigation.SETTINGS} element={<Pages.SettingsLayout />}>
          {/* This will load a default route when going to /settings  */}
          <Route index element={<Navigate to={Navigation.USER_PROFILE} />} />
          <Route path={Navigation.USER_PROFILE} element={<Pages.UserProfile />} />
        </Route>
        <Route path={Navigation.CLAIM_WRISTBAND} element={<Pages.ClaimWristbandLayout />}>
          <Route path={Navigation.CLAIM_WRISTBAND} element={<Pages.ClaimWristband />} />
        </Route>
        <Route path={Navigation.MY_TRANSACTIONS} element={<Pages.MyTransactionsLayout />}>
          <Route path={Navigation.MY_TRANSACTIONS} element={<Pages.MyTransactions />} />
        </Route>
      </Route>
    </Route>
    <Route path='/' element={<Pages.AuthLayout />}>
      <Route path={Navigation.AUTH_ACTION} element={<AuthActions />} />
      <Route
        path={Navigation.LOGIN}
        element={
          <RestrictedRoute>
            <Pages.Login />
          </RestrictedRoute>
        }
      />
      <Route
        path={Navigation.SIGN_UP}
        element={
          <RestrictedRoute>
            <Pages.SignUp />
          </RestrictedRoute>
        }
      />
      <Route
        path={Navigation.RESET_PASSWORD}
        element={
          <RestrictedRoute>
            <Pages.ResetPassword />
          </RestrictedRoute>
        }
      />
    </Route>
    <Route path={Navigation.SIGN_OUT} element={<Navigate to={Navigation.LOGIN} />} />
    {/* Don't put anything after this line */}
    <Route path='*' element={<Navigate to={Navigation.EVENTS} replace />} />
  </Routes>
);

export default Routing;
