import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { resetAll } from 'store/common';

export enum LedgerActions {
  FETCH_WALLET = 'ledger/fetchWallet',
  FETCH_WALLET_DETAILS = 'ledger/fetchWalletDetails',
}

type LedgersKeyValue = {
  [key: string]: any;
};

type WalletsKeyValue = {
  [key: string]: any;
};

export type LedgerState = {
  isLoading: boolean;
  isUpdatingWallet: boolean;
  ledgers: LedgersKeyValue;
  wallets: WalletsKeyValue;
  error: any;
};

const initialState = {
  isLoading: false,
  isUpdatingWallet: false,
  ledgers: {},
  wallets: {},
  error: null,
} as LedgerState;

export const fetchWalletDetails = createAsyncThunk(
  'ledger/fetchWalletDetails',
  async (props: any) => {
    const response = await props.securedApi?.DistributedLedgerApi.getWalletDetails(
      props.ledgerId,
      props.walletId,
    );
    response.ledgerId = props.ledgerId;
    return response;
  },
);

export const ledgerSlice = createSlice({
  name: 'ledgerData',
  initialState,
  reducers: {
    updateWalletBalance(state, action) {
      state.wallets[action.payload.ledgerId].walletBalance = action.payload.newWalletBalance;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export const { updateWalletBalance } = ledgerSlice.actions;
export default ledgerSlice.reducer;
