import React, { FC, useEffect, useState } from 'react';
import { Input } from 'reactstrap';

import { UpcomingEventsTable } from './components';
import { useFetchEvents } from './hooks';

import { Heading, Loader } from 'components';
import { useUser } from 'data';
import { useAuthValue } from 'hooks/Firebase';
import { selectEventsUpcomingEventsArray, useAppSelector } from 'store';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

const Home: FC = () => {
  const { currentUser } = useAuthValue();
  const { fetchUserData } = useUser();
  const upcomingEvents = useAppSelector(selectEventsUpcomingEventsArray);
  const isMobile = useMediaQuery();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (currentUser && currentUser?.emailVerified) {
      fetchUserData();
    }
  }, [fetchUserData, currentUser]);

  useFetchEvents();

  if (upcomingEvents === null) {
    return <Loader />;
  }

  return (
    <div className='w-full px-[1.5rem] py-[1.5rem] h-min-screen h-screen'>
      <div>
        {!isMobile ? (
          <Heading
            headingLevel='h2'
            className='flex items-center text-lg font-semibold text-base mb-4'
          >
            Welcome, {currentUser?.displayName} &nbsp;👋
          </Heading>
        ) : null}
        <div className={`flex gap-4 ${isMobile ? 'flex-col' : 'flex-row'}`}>
          <Input
            type='search'
            name='search'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className='flex-grow-3 flex-basis-3/4'
            placeholder='🔎   Search by event name'
          />
        </div>

        <UpcomingEventsTable searchQuery={searchQuery} />
      </div>
    </div>
  );
};

export default Home;
