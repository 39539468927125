export const paths = {
  getEvent: (eventId: string): string => `public/events/${eventId}`,
  getAllOrders: (nextPageToken?: string) =>
    `/orders/all${nextPageToken ? `?nextPageToken=${nextPageToken}` : ''}`,
  fetchUpcomingEvents: (nextPageToken?: string) =>
    `public/events?includePastEvents=false&pageSize=100${
      nextPageToken ? `&nextPageToken=${nextPageToken}` : ''
    }`,
  fetchOrderById: (id: string) => `/orders/all/${id}`,
  sendEmailReceipt: (orderId: string) => `/orders/${orderId}/sendOrderReceipt`,
  getProducts: (topUpFolioUuid: string) => `/folios/${topUpFolioUuid}/products`,
  createOrder: (topUpFolioUuid: string) => `/folios/${topUpFolioUuid}/orders`,
  fetchEventTransactionHistory: (eventId: string) => `/cb-ticketing/my-transactions/${eventId}`,
  getWalletByFolioId: (folioId: string) => `events/${folioId}/users/current/accountAssignations`,
  account: () => `/account`,
  getUserDetailedInfo: (email: string) => `/service/accountForEmail?email=${email}`,
  claimWristband: (folioId: string) => `folios/${folioId}/identityTokens/claim`,
  checkIfWristbandExists: (rfidUid: string, securityCode: string, folioId: string) =>
    `/folios/${folioId}/identityTokens/checkIdentity?rfidUid=${rfidUid}&securityCode=${securityCode}`,
  checkIfWristbandExistsSecurityCode: (securityCode: string, folioId: string) =>
    `/folios/${folioId}/identityTokens/checkIdentity?securityCode=${securityCode}`,
  getServiceIdentity: () => '/meta/pub/idt',
  manualRefundRequest: () => `/user/refunds/data`,
  refundHistory: (accountId: string, eventId: string) =>
    `/orders/${accountId}/events/${eventId}/refundOrders`,
  getCurrency: (folioId: string) => `/folios/${folioId}/salesSummary/currencyCode`,
  getVendorByFolioId: (orderId: string) => `/folios/${orderId}/vendorInfo`,
  getTopupTypes: (folioId: string, topupTypeId: string) =>
    `folios/${folioId}/topupTypes/${topupTypeId} `,
  getOrdersByRfid: (rfidUid: string) => `/orders/${rfidUid}/patronWebOrderList`,
  verifyToken: () => `/guest/verify`,
  getClaimConfigForEvent: (eventId: string) => `/events/${eventId}/claimCredentials`,
};
