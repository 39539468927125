import { Config } from '../Config';

import { ClientConfig, SecuredApi } from './Api';

import { paths } from 'data/constants';

interface AccountApi {
  updateUser: (body: any) => Promise<any>;
  getUserDetailedInfo: (email: string) => Promise<any>;
  verifyToken: (body: any) => Promise<any>;
}

export class AccountApiImpl extends SecuredApi implements AccountApi {
  authtokenTTL = 180;
  rfidSearch = '';
  clientConfig = (): ClientConfig => {
    return {
      baseURL: Config.ROOT_ISSUER,
      timeout: 15000,
    };
  };

  updateUser = async (body: any): Promise<any> => {
    try {
      const response = await this.client.post(paths.account(), body);

      if (response.status === 200) {
        await this.client.patch(paths.account(), body);
      }
    } catch (err) {
      console.log('updateUser:', err);
    }
  };

  getUserDetailedInfo = async (email: string): Promise<any> => {
    const response = await this.client.get(paths.getUserDetailedInfo(email));
    return response.data;
  };

  verifyToken = async (body: { token: string | undefined; siteKey: string }) => {
    try {
      const response = await this.client.post(paths.verifyToken(), body);
      return response;
    } catch (error) {
      console.error('Error during token verification:', error);
    }
  };
}

export default AccountApiImpl;
