import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';

import { Section } from 'components/Section';
import { buildCurrencyBalance, getCurrencySymbolForCurrencyCode } from 'helpers/currency';
import { useEventsApi, useLedger } from 'data';
import { selectEventCurrency, selectUserData, useAppSelector } from 'store';
import { isObjectEmpty } from 'helpers';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import MobileNavigationTabs from 'components/Menu/MobileNavigationTabs';
import useEventUri from 'hooks/useEventUri';
import FreezeWristband from 'pages/EventBalance/components/FreezeWristband';
import WalletInfo from 'pages/EventBalance/components/WalletInfo';

const EventLayout: FC = () => {
  const isMobile = useMediaQuery();
  const { eventId } = useParams();
  const { fetchSelectedEventById } = useEventsApi();
  const [openFreezeWristbandModal, setOpenFreezeWristbandModal] = useState(false);
  const selectedEvent = useSelector((state: any) => state.eventsData.selectedEvent);
  const eventUri = useEventUri();
  const currency = useSelector(selectEventCurrency);
  const currencySymbol = getCurrencySymbolForCurrencyCode(currency);
  const {
    fetchEventWallet,
    freezeWalletAction,
    unfreezeWalletAction,
    currentWalletBalance,
    currentWalletRFID,
    currentWallet,
    currenWalletId,
  } = useLedger();
  const balance =
    currentWalletBalance > 0
      ? buildCurrencyBalance(currency, currencySymbol, currentWalletBalance)
      : '';
  const title = selectedEvent?.eventSummary?.name?.en;
  const { sub: accountId } = useAppSelector(selectUserData);

  const onFreezeWallet = () => {
    if (isWalletFrozen) {
      unfreezeWalletAction(selectedEvent?.uuid, currenWalletId, accountId);
    } else {
      freezeWalletAction(selectedEvent?.uuid, currenWalletId, accountId);
    }

    setOpenFreezeWristbandModal(false);
  };

  // Case: page was reloaded or specific url pasted: load selected event + wallet
  useEffect(() => {
    if (isObjectEmpty(selectedEvent) && eventId) {
      fetchSelectedEventById(eventId);
    }
  }, [selectedEvent, eventId, fetchSelectedEventById]);

  useEffect(() => {
    if (selectedEvent && !currentWallet && eventUri) {
      fetchEventWallet(eventUri!);
    }
  }, [selectedEvent, currentWallet, fetchEventWallet, eventUri]);

  const isWalletFrozen = Boolean(currentWallet?.wallet?.isFrozen);
  const areWalletPaymentsEnabled = Boolean(selectedEvent?.eventDetails?.enableWalletPayments);

  return isMobile ? (
    <>
      <div className='w-full px-3 pt-3'>
        <div className='flex justify-between items-center text-lg font-medium text-custom-primary'>
          <div className=''>{title}</div>
          <div className=''>{balance}</div>
        </div>
        <WalletInfo
          currentWalletRFID={currentWalletRFID}
          currentWallet={currentWallet}
          setOpenFreezeWristbandModal={setOpenFreezeWristbandModal}
          isWalletFrozen={isWalletFrozen}
        />
        {areWalletPaymentsEnabled ? (
          <div className='flex justify-between items-center'></div>
        ) : null}
      </div>
      <MobileNavigationTabs />
      <div className='px-4'>
        <Outlet />
      </div>
      <FreezeWristband
        isOpen={openFreezeWristbandModal}
        onActionConfirmed={onFreezeWallet}
        onClose={() => setOpenFreezeWristbandModal(false)}
        isFrozen={isWalletFrozen}
      />
    </>
  ) : (
    <>
      <div className='text-custom-primary text-xl font-bold pb-4'>{title}</div>
      {/* Wallet Balance */}
      <div className='flex gap-4 justify-between'>
        {areWalletPaymentsEnabled ? (
          <Section
            label='Wallet Balance'
            value={balance}
            actionLabel={isWalletFrozen ? 'Unfreeze Wallet' : 'Freeze Wallet'}
            onActionClick={() => setOpenFreezeWristbandModal(true)}
          />
        ) : null}
        <Section label='Wristband/Card' value={currentWalletRFID || 'N/A'} />
        <Section label={'Security ID'} value={currentWallet?.securityCode || 'N/A'} />
      </div>
      <div className='pt-4'>
        <Outlet />
      </div>
      <FreezeWristband
        isOpen={openFreezeWristbandModal}
        onActionConfirmed={onFreezeWallet}
        onClose={() => setOpenFreezeWristbandModal(false)}
        isFrozen={isWalletFrozen}
      />
    </>
  );
};
export default EventLayout;
