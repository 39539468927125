import React from 'react';

const RefundIcon = () => {
  return (
    <svg width='19' height='21' viewBox='0 0 19 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.3828 13.0806V12.0806C13.3828 9.87143 11.592 8.08057 9.38281 8.08057H5.38281M5.38281 8.08057L8.38281 11.0806M5.38281 8.08057L8.38281 5.08057M17.3828 19.0806V3.08057C17.3828 1.976 16.4874 1.08057 15.3828 1.08057H3.38281C2.27824 1.08057 1.38281 1.976 1.38281 3.08057V19.0806L5.38281 17.0806L9.38281 19.0806L13.3828 17.0806L17.3828 19.0806Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default RefundIcon;
