import React, { useEffect, useState } from 'react';
import { applyActionCode, checkActionCode } from 'firebase/auth';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { auth } from '../../firebase';

import { Button } from 'components';

const EmailLinkVerify = () => {
  const { t } = useTranslation();
  const continueUrl = new URLSearchParams(location.search).get('continueUrl');

  const [verificationState, setVerificationState] = useState({
    loading: true,
    success: false,
    error: null,
  });

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const code = new URLSearchParams(window.location.search).get('oobCode');
        if (!code) {
          throw new Error('No verification code provided');
        }

        await checkActionCode(auth, code);
        await applyActionCode(auth, code);

        setVerificationState({
          loading: false,
          success: true,
          error: null,
        });
      } catch (error: any) {
        setVerificationState({
          loading: false,
          success: false,
          error: error.message,
        });
      }
    };

    verifyEmail();
  }, []);

  if (verificationState.loading) {
    return (
      <div className='text-center'>
        <h3 className='mb-3'>{t('emailVerification.verifyLoadingTitle')}</h3>
        <p className='text-muted'>{t('emailVerification.verifyLoadingDescription')}</p>
      </div>
    );
  }

  if (verificationState.error) {
    return (
      <div className='text-center'>
        <Alert
          color='danger'
          className='text-left border-left border-danger mb-4'
          style={{
            borderLeft: '4px solid #dc3545',
            backgroundColor: '#fff1f0',
          }}
        >
          <div className='d-flex align-items-center mb-2'>
            <h4 className='alert-heading mb-0'>{t('emailVerification.verifyFailedTitle')}</h4>
          </div>
          <p className='mb-0'>{t('emailVerification.verifyFailedDescription')}</p>
        </Alert>
      </div>
    );
  }

  if (verificationState.success) {
    const handleNavigate = (): void => {
      if (continueUrl) {
        // For external URLs, use window.location
        window.location.href = continueUrl;
      }
    };

    return (
      <div className='text-center'>
        <Alert
          color='success'
          className='text-left border-left mb-4'
          style={{
            borderLeft: '4px solid #28a745',
            backgroundColor: '#f0fff4',
          }}
        >
          <div className='d-flex align-items-center mb-2'>
            <h4 className='alert-heading mb-0'>{t('emailVerification.verifySuccessTitle')}</h4>
          </div>
          <p className='mb-0'>{t('emailVerification.verifySuccessDescription')}</p>
        </Alert>
        {continueUrl ? (
          <>
            <p className='mb-3'>{t('emailVerification.verifySuccessInstructionWithURL')}</p>
            <Button
              onClick={() => handleNavigate()}
              className='btn btn-primary d-inline-flex align-items-center px-4 text-white'
            >
              {t('emailVerification.verifySuccessButtonReturnToLogin')}
            </Button>
          </>
        ) : (
          <p className='mb-3'>{t('emailVerification.verifySuccessInstructionWithoutURL')}</p>
        )}
      </div>
    );
  }

  return null;
};

export default EmailLinkVerify;
