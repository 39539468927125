import React, { FC } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'components';

type ModalProps = {
  isOpen: boolean;
  isFrozen: boolean;
  onActionConfirmed: () => void;
  onClose: () => void;
};

const freezeText =
  'Freezing this wallet means you will no longer be able to spend from your wallet';
const unfreezeText = 'Unfreezing this wallet means you will be able to spend from your wallet';
const freezeTitle = 'Are you sure you want to freeze this wallet?';
const unfreezeTitle = 'Are you sure you want to unfreeze this wallet?';

const FreezeWristband: FC<ModalProps> = ({ isOpen, onActionConfirmed, onClose, isFrozen }) => {
  return (
    <Modal toggle={onClose} isOpen={isOpen} centered size={'md'} className='rounded-lg'>
      <ModalBody className='flex flex-col items-center text-center p-6'>
        <div className='bg-gray-100 p-3 rounded-full mb-4'>
          <FontAwesomeIcon icon={faCreditCard} />
        </div>

        <h2 className='text-lg font-semibold text-gray-900'>
          {!isFrozen ? freezeTitle : unfreezeTitle}
        </h2>

        <p className='text-gray-600 text-sm mt-2 mx-4'>{!isFrozen ? freezeText : unfreezeText}</p>
      </ModalBody>

      <div className='flex gap-3 w-full px-6 pb-6'>
        <Button
          className='w-full border border-gray-300 text-gray-700 bg-white hover:bg-gray-100 rounded-md py-2'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className='w-full bg-green-500 text-white hover:bg-green-600 rounded-md py-2'
          onClick={onActionConfirmed}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default FreezeWristband;
