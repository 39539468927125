import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { TicketsType, AccountTicketsType } from 'data';
import { resetAll } from 'store/common';
import { setRejectedState, setPendingState, setFulfilledState } from 'store/common/commonActions';
import { createTopUpOrder, fetchProductsAction, sendEmailReceiptAction } from 'store/orders';
import { WalletInfo } from 'data/api/types/LedgerApiTypes';

type topupTokensKeyValue = {
  [key: string]: any;
};

export const fetchWallet = createAsyncThunk('orders/fetchWallet', async (props: any) => {
  const response = await props.securedApi?.OrdersApi.getWalletByFolioId(props.eventId);
  return response;
});

export const freezeWallet = createAsyncThunk('orders/freezeWallet', async (props: any) => {
  const response = await props.securedApi?.OrdersApi.freezeWallet(
    props.eventId,
    props.walletId,
    props.accountId,
  );
  return response;
});

export const unfreezeWallet = createAsyncThunk('orders/unfreezeWallet', async (props: any) => {
  const response = await props.securedApi?.OrdersApi.unfreezeWallet(
    props.eventId,
    props.walletId,
    props.accountId,
  );
  return response;
});

export type OrdersState = {
  tickets: TicketsType | Record<string, never>;
  accountTickets: AccountTicketsType | Record<string, never>;
  currentWallet?: WalletInfo;
  orders: any;
  selectedOrder: any;
  topupTokens: topupTokensKeyValue;
  response: any;
  status: 'idle' | 'loading' | 'failed';
};

const initialState = {
  currentWallet: undefined,
  response: null,
  topupTokens: {},
  status: 'idle',
} as OrdersState;

export const ordersSlice = createSlice({
  name: 'ordersData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetAll, () => initialState)
      // sendReceipt
      .addCase(sendEmailReceiptAction.pending, setPendingState)
      .addCase(sendEmailReceiptAction.rejected, setRejectedState)
      .addCase(sendEmailReceiptAction.fulfilled, setFulfilledState)
      // createTopUpOrder
      .addCase(createTopUpOrder.pending, setPendingState)
      .addCase(createTopUpOrder.rejected, setRejectedState)
      .addCase(createTopUpOrder.fulfilled, (state, action) => {
        state.response = action.payload;
        state.status = 'idle';
      })
      // fetchProductsAction
      .addCase(fetchProductsAction.pending, setPendingState)
      .addCase(fetchProductsAction.rejected, setRejectedState)
      .addCase(fetchProductsAction.fulfilled, (state, action) => {
        //Create key value pair for topup tokens with topUpFolioId as key
        const key = action?.meta?.arg?.topUpFolioId;
        const entries = action.payload?.entries;
        const tokens = entries.filter((entry: any) => {
          if (entry?.productSummary?.kind === 'token') {
            return entry?.uuid;
          }
        });
        const value = tokens[0] ?? null;
        state.topupTokens[key] = value;
        state.status = 'idle';
      })

      .addCase(fetchWallet.fulfilled, (state, action) => {
        const value = action.payload;
        state.currentWallet = value;
      })
      .addCase(freezeWallet.fulfilled, (state, action) => {
        state.currentWallet = {
          ...state.currentWallet!,
          wallet: action.payload.result.wallet,
        };
      })
      .addCase(unfreezeWallet.fulfilled, (state, action) => {
        state.currentWallet = {
          ...state.currentWallet!,
          wallet: action.payload.result.wallet,
        };
      });
  },
});

export default ordersSlice.reducer;
