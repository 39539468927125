import React, { FC, useCallback, useMemo, useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link, NavLink } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import WalletId from './WalletId';

import { useSecondaryMenuLinks } from 'hooks/navigation/useSecondaryMenuLinks';
import { Heading, ImageSrcSet, Menu } from 'components';
import { useMenuLinks } from 'hooks';
import { useAuthValue } from 'hooks/Firebase';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { Navigation } from 'navigation';
import { FooterMenu } from 'components/FooterMenu';

const SideBar: FC = () => {
  const [isNavVisible, setIsNavVisible] = useState<boolean>(false);
  const isMobile = useMediaQuery();
  const { currentUser } = useAuthValue();
  const { links, parentSectionPath } = useMenuLinks();
  const secondaryLinks = useSecondaryMenuLinks();

  const logoHref = useMemo(
    () => (!currentUser?.emailVerified ? Navigation.LOGIN : Navigation.EVENTS),
    [currentUser?.emailVerified],
  );

  const closeNav = useCallback(() => {
    setTimeout(() => {
      document.body.classList.remove('scroll-lock');
      setIsNavVisible(false);
    }, 1);
  }, [setIsNavVisible]);

  const onMenuClick = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.body.classList.toggle('scroll-lock');
    setIsNavVisible((isVisible) => !isVisible);
  }, [setIsNavVisible]);

  return (
    <>
      {isMobile ? (
        <nav className='flex'>
          <div className='w-full md:hidden bg-[#00303B] z-10'>
            <div className='flex justify-between items-center'>
              <a href={logoHref}>
                <div className='px-4'>
                  <img className={`bg-none w-[24px] h-[24px]`} src={`/logo192.png`} alt='logo' />
                </div>
              </a>
              <WalletId />
              <button
                className='btn text-white px-4'
                onClick={isNavVisible ? closeNav : onMenuClick}
                title='menu'
              >
                {isNavVisible ? <FiX className='text-2xl' /> : <FiMenu className='text-2xl' />}
              </button>
            </div>

            {isNavVisible && (
              <>
                <div
                  className='fixed top-14 right-0 bottom-0 left-0 bg-black opacity-50 mt-[4px]'
                  onClick={closeNav}
                ></div>
                <div className='fixed top-14 right-0 bottom-0 bg-white w-4/5 max-w-[80%] mt-[4px] shadow-lg'>
                  <Heading
                    headingLevel='p'
                    className='flex px-3 mt-[4px] h-[62px] items-center font-normal text-base'
                  >
                    Welcome, {currentUser?.displayName} 👋
                  </Heading>
                  <ul>
                    {links.map(({ name, to, icon, onClick, isHidden }) => (
                      <>
                        {!isHidden ? (
                          <li key={name} className='p-3 hover:bg-gray-200 border-t border-gray-100'>
                            <NavLink
                              to={to}
                              onClick={() => {
                                if (onClick) onClick();
                                closeNav();
                              }}
                              end
                              className='flex items-center'
                            >
                              <div className='h-[20px] w-[20px] mr-4'>{icon}</div>
                              <span>{name}</span>
                            </NavLink>
                          </li>
                        ) : null}
                      </>
                    ))}
                    {secondaryLinks.map(({ name, to, icon, onClick, isHidden }) => (
                      <>
                        {!isHidden ? (
                          <li key={name} className='p-3 hover:bg-gray-200 border-t border-gray-100'>
                            <NavLink
                              to={to}
                              onClick={() => {
                                if (onClick) onClick();
                                closeNav();
                              }}
                              end
                              className='flex items-center'
                            >
                              <div className='h-[20px] w-[20px] mr-4'>{icon}</div>
                              <span>{name}</span>
                            </NavLink>
                          </li>
                        ) : null}
                      </>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        </nav>
      ) : (
        <div className='fixed top-0 left-0 h-full md:w-[226px] bg-[#00303B]'>
          <div className='flex flex-col justify-between h-full'>
            <div className=''>
              <div className='py-2 pl-3 flex gap-4 flex-col'>
                <Link to={logoHref}>
                  <ImageSrcSet
                    alt='logo'
                    srcSet='/logo-all/cb-logo/Logo'
                    className='bg-none h-[32px]'
                  />
                </Link>
                <div className='flex-1 min-w-0'>
                  <div className='flex flex-row items-center flex-wrap text-white'>
                    <div
                      className='font-semibold text-lg truncate'
                      data-tooltip-id='username'
                      data-tooltip-content={currentUser?.displayName}
                    >
                      {currentUser?.displayName}
                    </div>
                    <div
                      className='text-sm text-intellitix-inactive truncate'
                      data-tooltip-id='email'
                      data-tooltip-content={currentUser?.email}
                    >
                      {currentUser?.email}
                    </div>
                  </div>
                </div>
              </div>
              <hr className='text-white p-2' />
              <Menu links={links} parentSectionPath={parentSectionPath} variant='primary' />
            </div>
            <FooterMenu links={secondaryLinks} variant='primary' />
          </div>
          <ReactTooltip id='email' place='right-end' />
          <ReactTooltip id='username' place='right-end' />
        </div>
      )}
    </>
  );
};

export default SideBar;
